import { forwardRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styled from "styled-components";
import { breakPoints } from "../constants/breakpoints";
import Page from "./Page";

const HomeCarouselRoot = styled(Page)`
  @keyframes fadeInUpSD {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }

    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  .slick-slide {
    overflow: hidden;
  }

  .slick-slide > div {
    width: 100%;
    height: 100vh;
  }

  .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: "left top";
    transition: all 600ms ease-in-out;
    transform: scale(1.2, 1.2);
  }

  .slick-dots {
    z-index: 99;
    bottom: 3.75rem;
  }

  .slick-dots li {
    display: inline-block;
    width: 20px;
    margin: 5px;
  }

  .slick-dots li button {
    display: inline-block;
    width: 20px;
    height: 4px;
  }

  .slick-dots li button:before {
    content: "";
    width: 20px;
    height: 4px;
    background-color: #fff;
    border-radius: 1px;
    opacity: 0.25;
    box-shadow: 0px 0px 2px 1px #393939b0;
  }

  .slick-dots li.slick-active button:before {
    color: #c90000;
    background-color: #c90000;
    opacity: 1;
  }

  .slick-active {
    img {
      transform: scale(1, 1);
    }
  }

  .slick-active .content-inner {
    animation-name: fadeInUpSD;
    animation-duration: 1s;
    opacity: 1;
  }
`;

const CarouselItem = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(5, 5, 5);
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 20%) 0%,
      rgb(0 0 0 / 50%) 50%,
      rgba(0, 0, 0, 0) 99%
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 40px 30px 40px;
    z-index: 9;

    @media ${breakPoints.xl} {
      width: 45%;
      background: linear-gradient(
        90deg,
        rgb(0 0 0 / 70%) 0%,
        rgb(0 0 0 / 50%) 60%,
        rgba(0, 0, 0, 0) 99%
      );
    }

    .content-inner {
      width: 75%;
      margin: 0 auto;
      text-align: center;

      margin-bottom: auto;
      padding-top: 120px;

      @media ${breakPoints.sm} {
        margin-bottom: unset;
        padding-top: 0;
      }

      h1.display-5 {
        @media ${breakPoints.md} {
          white-space: nowrap;
        }
      }

      @media ${breakPoints.xl} {
        width: 100%;
        margin: unset;
        text-align: left;
      }
    }

    h1 {
      text-shadow: 0px 0px 0.875rem black;
    }

    .cta-container {
      margin-top: 2rem;

      a:link,
      a:visited,
      a:hover,
      a:active {
        position: relative;
        text-decoration: none;
        display: inline-block;
        font-size: 1.25rem;
        color: #fff;
        font-weight: semibold;
        border-bottom: 2px solid red;
        transition: all 0.3s ease-in-out;
      }

      a:hover {
        transform: scale(1.05);
      }
    }
  }
`;

const HomeCarousel = forwardRef<HTMLElement>(function HomeCarousel(_, ref) {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8500,
    pauseOnHover: false,
    fade: true,
  };

  return (
    <HomeCarouselRoot $position="relative" id="home" ref={ref}>
      <Slider {...settings}>
        <CarouselItem className="carouselItem">
          <picture className="picture picture--cover hero__slide">
            <source
              media="(max-width: 767.98px)"
              srcSet={`${process.env.PUBLIC_URL}/images/home/interior-768x1024.webp`}
            />
            <source
              media="(min-width: 991.98px)"
              srcSet={`${process.env.PUBLIC_URL}/images/home/interior-2560x1440.webp`}
            />
            <img
              srcSet={`${process.env.PUBLIC_URL}/images/home/interior-2560x1440.webp`}
              alt="Interior Designing Background"
              loading="lazy"
            />
          </picture>
          <div className="content">
            <div className="content-inner">
              <h1 className="display-5 text-white fw-semibold mb-3">
                Interior Designing
              </h1>
              <p className="text-white fw-normal">
                We offer the creative touch that makes every interior a pinnacle
                point of elegance. We are a team of pioneers in the Interior
                industry upholding your vision in mind.
              </p>
              <div className="cta-container">
                <Link to="/projects/interior" className="btn-cta">
                  What we do
                </Link>
              </div>
            </div>
          </div>
        </CarouselItem>
        <CarouselItem className="carouselItem">
          <picture className="picture picture--cover hero__slide">
            <source
              media="(max-width: 767.98px)"
              srcSet={`${process.env.PUBLIC_URL}/images/home/arch-768x1024.webp`}
            />
            <source
              media="(min-width: 991.98px)"
              srcSet={`${process.env.PUBLIC_URL}/images/home/arch-2560x1440.webp`}
            />
            <img
              srcSet={`${process.env.PUBLIC_URL}/images/home/arch-2560x1440.webp`}
              alt=""
              loading="lazy"
            />
          </picture>
          <div className="content">
            <div className="content-inner">
              <h1 className="display-5 text-white fw-semibold mb-3">
                Architecture Design
              </h1>
              <p className="text-white fw-normal">
                Forever wouldn't suffice when it comes to great architecture. We
                generate ideas that surpass usual expectations combined with the
                expertise of our team to create structures that challenge the
                concept of time.
              </p>
              <div className="cta-container">
                <Link to="/projects/archdesign" className="btn-cta">
                  What we do
                </Link>
              </div>
            </div>
          </div>
        </CarouselItem>
        <CarouselItem className="carouselItem">
          <picture className="picture picture--cover hero__slide">
            <source
              media="(max-width: 767.98px)"
              srcSet={`${process.env.PUBLIC_URL}/images/home/vr-768x1024.webp`}
            />
            <source
              media="(min-width: 991.98px)"
              srcSet={`${process.env.PUBLIC_URL}/images/home/vr-2560x1440.webp`}
            />
            <img
              srcSet={`${process.env.PUBLIC_URL}/images/home/vr-2560x1440.webp`}
              alt=""
              loading="lazy"
            />
          </picture>
          <div className="content">
            <div className="content-inner">
              <h1 className="display-5 text-white fw-semibold mb-3">
                VR Virtual Reality
              </h1>
              <p className="text-white fw-normal">
                Why wait to experience wonders? Our Virtual Reality options
                provide you an immersive experience of having a real-life view
                of the finished design.
              </p>
              <div className="cta-container">
                <Link to="/projects/vr" className="btn-cta">
                  What we do
                </Link>
              </div>
            </div>
          </div>
        </CarouselItem>
        <CarouselItem className="carouselItem">
          <picture className="picture picture--cover hero__slide">
            <source
              media="(max-width: 767.98px)"
              srcSet={`${process.env.PUBLIC_URL}/images/home/prv1-768x1024.webp`}
            />
            <source
              media="(min-width: 991.98px)"
              srcSet={`${process.env.PUBLIC_URL}/images/home/prv1-2560x1440.webp`}
            />
            <img
              srcSet={`${process.env.PUBLIC_URL}/images/home/prv1-2560x1440.webp`}
              alt="Product Rendering"
              loading="lazy"
              style={{ objectPosition: "left top" }}
            />
          </picture>
          <div className="content">
            <div className="content-inner">
              <h1 className="display-5 text-white fw-semibold mb-3">
                Product Rendering
              </h1>
              <p className="text-white fw-normal">
                Our product rendering services grant you the opportunity to
                partake in the process of creating wonderful interiors. We
                believe in providing you with the option to be a part of design
                creation.
              </p>
              <div className="cta-container">
                <Link to="/projects/productrender" className="btn-cta">
                  What we do
                </Link>
              </div>
            </div>
          </div>
        </CarouselItem>
      </Slider>
    </HomeCarouselRoot>
  );
});

export default HomeCarousel;
