import classNames from "classnames";
import { ReactNode } from "react";
import { Nav } from "react-bootstrap";
import { matchPath, NavigateFunction } from "react-router-dom";
import type { Location } from "history";

export type NavLinkProps = {
  children: ReactNode;
  to: string;
  location: Location;
  navigate: NavigateFunction;
  onClick?: () => void;
  isActive?: (location: Location) => boolean;
};
function NavLink({
  to,
  children,
  isActive,
  location,
  navigate,
  onClick,
}: NavLinkProps) {
  const isLinkActive =
    isActive && typeof isActive === "function"
      ? isActive(location)
      : !!matchPath(to, location.pathname);

  return (
    <Nav.Link
      href={to}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(to);
        onClick?.();
      }}
      className={classNames({ activeLink: isLinkActive })}
    >
      {children}
    </Nav.Link>
  );
}

export default NavLink;
