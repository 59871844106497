import axios from "axios";
import { AssessmentRequest, AssessmentResponse } from "../types/RecaptchaV3";

function VerifyCaptcha(
  verifyRequest: AssessmentRequest
): Promise<AssessmentResponse> {
  return new Promise<AssessmentResponse>(async (resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/captcha/verify`;
      const response = await axios.post<AssessmentResponse>(
        url,
        verifyRequest,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
}

export { VerifyCaptcha };
