import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import LazyImage from "./LazyImage";
import { ImLocation2 } from "react-icons/im";
import { Skeleton } from "@mui/material";
import { Project } from "../types/Projects";
import { GetCategoryByKey, GetProjectsByCategory } from "../services/Projects";
import { ProjectCategory } from "../types/ProjectCategory";
import { CgStack } from "react-icons/cg";

const ProjectListRoot = styled.div`
  padding: 0.9375rem;

  .title {
    display: flex;
    justify-content: space-between;
    color: #fff;
    margin-bottom: 15px;
  }

  .disclaimer {
    display: inline-block;
    color: red;
    small {
      font-size: 9px;
    }
  }

  .no-projects {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 60px;

    .nodata-content {
      text-align: center;
      user-select: none;
      color: #fff;
      img {
        max-width: 12.5rem;
        height: auto;
        user-select: none;
      }
    }
  }

  .project-list-grid {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 1.25rem;
    min-height: 6.25rem;

    .loader {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff;
    }

    .project-image-title {
      background-color: transparent;
      padding: 0.3125rem 0.3125rem 0.3125rem 0.1875rem;
      color: #dfdfdf;
      font-weight: normal;
      font-size: 0.875rem;
      width: 100%;
      margin-bottom: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .project-item {
      width: 100%;
      position: relative;
      min-width: 0;
      min-height: 0;
      overflow: hidden;

      .project-img-container {
        position: relative;
        min-width: 0;
        min-height: 0;
        overflow: hidden;
        min-height: 11.25rem;
        border: 3px solid #fff;

        .project-info-overlay {
          cursor: pointer;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          top: 100%;
          padding: 10px 10px 10px 10px;
          background-color: rgba(0, 0, 0, 0.6);
          transition: top 0.25s linear;
          color: #fff;
          display: flex;
          align-items: center;
          overflow: hidden;

          .content {
            width: 100%;
          }

          .project-name {
            text-align: center;
            margin-bottom: 20px;
          }

          .project-location {
            display: flex;
            justify-content: center;
            .text {
              text-align: center;
              line-height: 1;
            }
          }
        }

        &:hover {
          .project-info-overlay {
            top: 0;
          }

          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
`;

function ProjectList() {
  const navigate = useNavigate();
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [categoryInfo, setCategoryInfo] = useState<ProjectCategory | null>(
    null
  );
  const [projects, setProjects] = useState<Project[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { category = "interior" } = useParams();

  useEffect(() => {
    async function LoadProjects() {
      try {
        setCategoryLoading(true);
        const cateoryResponse = await GetCategoryByKey(category);
        setCategoryLoading(false);
        setCategoryInfo(cateoryResponse);
        if (cateoryResponse) {
          setProjectsLoading(true);
          const projects = await GetProjectsByCategory(cateoryResponse.key);
          setProjectsLoading(false);
          setProjects(projects);
          setError(null);
        } else {
          navigate("/projects");
        }
      } catch {
        setCategoryLoading(false);
        setProjectsLoading(false);
        setError("Something went wrong in fetching projects");
      }
    }
    setCategoryLoading(true);
    setProjectsLoading(true);
    LoadProjects();
  }, [category, navigate]);

  const handleProjectThumbClick = (id: number) => {
    navigate(`/projects/${category}/${id}`);
  };

  return (
    <ProjectListRoot>
      {categoryLoading && (
        <Skeleton
          sx={{ bgcolor: "grey.900", fontSize: "1.25rem" }}
          width={200}
          variant="text"
          animation="wave"
        />
      )}

      {!categoryLoading && error === null && !!categoryInfo && (
        <h5 className="title">{categoryInfo.name}</h5>
      )}

      {!projectsLoading && error === null && projects.length > 0 && (
        <section className="project-list-grid">
          {projects.map(
            ({
              id,
              blurhash,
              directoryName,
              description,
              name,
              location,
              isDummy,
            }) => (
              <div
                className="project-item"
                key={id}
                onClick={() => handleProjectThumbClick(id)}
              >
                <div className="project-img-container">
                  <LazyImage
                    src={`${process.env.PUBLIC_URL}/images/projects/${category}/${directoryName}/thumbs/project-thumb.webp`}
                    alt={name}
                    blurhash={blurhash}
                    watermark={isDummy ? "Dummy Project" : null}
                  />
                  <div className="project-info-overlay">
                    <div className="content">
                      <h6 className="project-name">{name}</h6>
                      <div className="project-location">
                        <div className="d-flex align-items-center">
                          <ImLocation2 />
                          <span className="text">{location}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="project-image-title">
                  <CgStack />
                  &nbsp;
                  {name}
                </h6>
              </div>
            )
          )}
        </section>
      )}

      {!projectsLoading && error === null && projects.length <= 0 && (
        <section className="no-projects">
          <div className="nodata-content">
            <img
              src={`${process.env.PUBLIC_URL}/images/BlankCanvas.svg`}
              alt="No projects added"
            />
            Something awesome is coming.
            <br />
            Please check again later.
          </div>
        </section>
      )}

      {(categoryLoading || projectsLoading) && (
        <section className="project-list-grid">
          {Array.from({ length: 5 }).map((_, index) => (
            <div className="project-item" key={index}>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                variant="rectangular"
                width="100%"
                height={250}
                animation="wave"
              />
            </div>
          ))}
        </section>
      )}
    </ProjectListRoot>
  );
}

export default ProjectList;
