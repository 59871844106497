import styled from "styled-components";
import { breakPoints } from "../constants/breakpoints";

const ExpandCollapseLineRoot = styled.div`
  display: none;
  position: relative;
  width: 0.125rem;
  height: 100%;
  background-color: #333;

  @media ${breakPoints.lg} {
    display: block;
  }

  button {
    position: absolute;
    top: 50%;
    left: 0.0625rem;
    border: none !important;
    outline: none !important;
    background-color: #333;
    color: #fff;
    padding: 0.0625rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

function ExpandCollapseLine() {
  return (
    <ExpandCollapseLineRoot>
      {/* <button type="button">
        <MdOutlineChevronRight />
      </button> */}
    </ExpandCollapseLineRoot>
  );
}

export default ExpandCollapseLine;
