import Page from "../components/Page";
import styled from "styled-components";
import { breakPoints } from "../constants/breakpoints";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Variants, motion, useAnimation } from "framer-motion";

const WhatWeDoRoot = styled.div`
  position: relative;
  width: 100%;
  background-color: #070707;
  min-height: 100vh;
  padding: 8.75rem 0.9375rem 0.9375rem 0.9375rem;

  .service-container {
    overflow: hidden;
  }

  @media ${breakPoints.lg} {
    padding: 11.25rem 1.875rem 1.875rem 1.875rem;
  }

  .header {
    font-size: 2rem;
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #fd0018;
    text-underline-offset: 0.75rem;
    text-decoration-thickness: 0.3125rem;
    text-align: center;

    @media ${breakPoints.sm} {
      text-align: left;
      padding-left: 14px;
    }
  }

  .container-fluid {
    .service {
      padding: 12px;
      margin-bottom: 2.5rem;

      @media ${breakPoints.sm} {
        margin-bottom: 0;
      }

      h3 {
        color: #fff;
        text-align: center;
        @media ${breakPoints.sm} {
          text-align: left;
        }
      }

      img {
        display: block;
        width: auto;
        height: 90px;
        margin: 0 auto 1rem auto;

        @media ${breakPoints.sm} {
          margin: 0 0 1rem 0;
        }
      }

      .text-container {
        min-height: 145px;

        p {
          color: #cbcbcb;
          margin: 0;
          text-align: center;
          @media ${breakPoints.sm} {
            text-align: left;
          }
        }
        margin-bottom: 1rem;
      }

      .button-container {
        text-align: center;

        @media ${breakPoints.sm} {
          text-align: left;
        }

        a.viewmore {
          display: inline-block;
          border: 0;
          outline: 0;
          padding: 8px 32px 8px 32px;
          text-decoration: none;
          border-radius: 500px;

          &:hover {
            background-color: #c61010;
          }
        }

        a.viewmore:link,
        a.viewmore:visited,
        a.viewmore:active {
          background-color: #b10e0e;
          color: #fff;
        }
      }
    }
  }
`;

const headingVariant: Variants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { delay: 0.1, duration: 0.5 },
  },
  hidden: {
    opacity: 0.2,
    x: "-100%",
    transition: { delay: 0.1, duration: 0.5 },
  },
};

const service1Variant: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.1, duration: 0.5, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    y: "100%",
    transition: { delay: 0.1, duration: 0.5, ease: "easeInOut" },
  },
};

const service2Variant: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.7, duration: 0.5, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    y: "100%",
    transition: { delay: 0.7, duration: 0.5, ease: "easeInOut" },
  },
};

const service3Variant: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 1.3, duration: 0.5, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    y: "100%",
    transition: { delay: 1.3, duration: 0.5, ease: "easeInOut" },
  },
};

const service4Variant: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 1.9, duration: 0.5, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    y: "100%",
    transition: { delay: 1.9, duration: 0.5, ease: "easeInOut" },
  },
};

const WhatWeDo = forwardRef<HTMLElement>(function WhatWeDo(_, sectionRef) {
  const control = useAnimation();

  const ref = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const refVal = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.2, root: null } // Adjust threshold as needed
    );

    if (refVal) {
      observer.observe(refVal);
    }

    return () => {
      if (refVal) {
        observer.unobserve(refVal);
      }
    };
  }, []);

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Page id="whatwedo" $height="auto" $minHeight="100vh" ref={ref}>
      <WhatWeDoRoot ref={sectionRef as any}>
        <motion.h1
          variants={headingVariant}
          initial="hidden"
          animate={control}
          className="header mb-3"
        >
          WHAT WE DO?
        </motion.h1>
        <div className="container-fluid pt-3 pt-lg-5">
          <div className="row">
            <div className="service-container col-12 col-sm-6 col-lg-4 col-xxl-3">
              <motion.section
                className="service"
                variants={service1Variant}
                initial="hidden"
                animate={control}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/whatwedo/service-interior.png`}
                  alt="Interior Designing"
                />
                <h3 className="mb-3">Interior Designing</h3>
                <div className="text-container">
                  <p>
                    We offer the creative touch that makes every interior a
                    pinnacle point of elegance. We are a team of pioneers in the
                    Interior industry upholding your vision in mind.
                  </p>
                </div>
                <div className="button-container">
                  <Link to="/projects/interior" className="viewmore">
                    View more
                  </Link>
                </div>
              </motion.section>
            </div>
            <div className="service-container col-12 col-sm-6 col-lg-4 col-xxl-3">
              <motion.section
                className="service"
                variants={service2Variant}
                initial="hidden"
                animate={control}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/whatwedo/service-architectural.png`}
                  alt="Architecture Design"
                  className="mb-3"
                />
                <h3 className="mb-3">Architecture Design</h3>
                <div className="text-container">
                  <p>
                    Forever wouldn't suffice when it comes to great
                    architecture. We generate ideas that surpass usual
                    expectations combined with the expertise of our team to
                    create structures that challenge the concept of time.
                  </p>
                </div>
                <div className="button-container">
                  <Link to="/projects/archdesign" className="viewmore">
                    View more
                  </Link>
                </div>
              </motion.section>
            </div>
            <div className="service-container col-12 col-sm-6 col-lg-4 col-xxl-3">
              <motion.section
                className="service"
                variants={service3Variant}
                initial="hidden"
                animate={control}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/whatwedo/service-vr.png`}
                  alt="Virtual Reality"
                  className="mb-3"
                />
                <h3 className="mb-3">Virtual Reality</h3>
                <div className="text-container">
                  <p>
                    Why wait to experience wonders? Our Virtual Reality options
                    provide you an immersive experience of having a real-life
                    view of the finished design.
                  </p>
                </div>
                <div className="button-container">
                  <Link to="/projects/vr" className="viewmore">
                    View more
                  </Link>
                </div>
              </motion.section>
            </div>
            <div className="service-container col-12 col-sm-6 col-lg-4 col-xxl-3">
              <motion.section
                className="service"
                variants={service4Variant}
                initial="hidden"
                animate={control}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/whatwedo/service-productrender.png`}
                  alt="Product Rendering"
                  className="mb-3"
                />
                <h3 className="mb-3">Product Rendering</h3>
                <div className="text-container">
                  <p>
                    Our product rendering services grant you the opportunity to
                    partake in the process of creating wonderful interiors. We
                    believe in providing you with the option to be a part of
                    design creation.
                  </p>
                </div>
                <div className="button-container">
                  <Link to="/projects/productrender" className="viewmore">
                    View more
                  </Link>
                </div>
              </motion.section>
            </div>
          </div>
        </div>
      </WhatWeDoRoot>
    </Page>
  );
});

export default WhatWeDo;
