import { useRef, useEffect } from "react";
import { Panzoom as NativePanzoom } from "@fancyapps/ui/dist/panzoom.esm.js";
import "@fancyapps/ui/dist/panzoom.css";
import { Controls } from "@fancyapps/ui/dist/panzoom.controls.esm.js";
import "@fancyapps/ui/dist/panzoom.controls.css";
import { SwipeEventData, useSwipeable } from "react-swipeable";
import styled from "styled-components";

NativePanzoom.Plugins = {
  Controls,
};

export type PanzoomProps = {
  src: string;
  alt: string;
  options: any;
  onSwipe?: (event: SwipeEventData) => void;
};

const PanZoomRoot = styled.div`
  .panzoom__controls {
    button {
      background-color: #373737;
      color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 0;

      &:first-child {
        border-bottom: none;
      }
    }
  }
`;

function Panzoom({ src, alt, options, onSwipe }: PanzoomProps) {
  const wrapper = useRef<HTMLDivElement>(null);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (
        wrapper.current &&
        !wrapper.current.classList.contains("is-draggable")
      ) {
        onSwipe?.(eventData);
      }
    },
  });

  useEffect(() => {
    const instance = new NativePanzoom(wrapper.current, options || {});

    return () => {
      instance.destroy();
    };
  }, [options]);

  return (
    <PanZoomRoot className="panzoom" ref={wrapper}>
      <img className="panzoom__content" {...handlers} src={src} alt={alt} />
    </PanZoomRoot>
  );
}

export default Panzoom;
