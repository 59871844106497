import { ALL_CATEGORIES, ALL_PROJECTS } from "../config/projectStub";
import { ProjectCategory, ProjectCategoryKey } from "../types/ProjectCategory";
import { Project } from "../types/Projects";

export function GetCategoryByKey(
  categoryKey: string
): Promise<ProjectCategory | null> {
  return new Promise<ProjectCategory | null>((resolve, reject) => {
    const validCategories = ["interior", "archdesign", "vr", "productrender"];
    if (validCategories.includes(categoryKey.toLowerCase())) {
      const key = categoryKey as ProjectCategoryKey;
      const category = ALL_CATEGORIES.find((x) => x.key === key) ?? null;
      setTimeout(() => {
        resolve(category);
      }, 300);
    } else {
      resolve(null);
    }
  });
}

export function GetProjectsByCategory(category: string): Promise<Project[]> {
  return new Promise<Project[]>((resolve, reject) => {
    const validCategories = ["interior", "archdesign", "vr", "productrender"];
    if (validCategories.includes(category.toLowerCase())) {
      const categoryKey = category as ProjectCategoryKey;
      const projects = ALL_PROJECTS.filter(
        (x) => x.category.key === categoryKey
      );
      setTimeout(() => {
        resolve(projects);
      }, 300);
    } else {
      reject(new Error("Invalid Category"));
    }
  });
}

export function GetProjectById(id: number): Promise<Project | null> {
  return new Promise<Project | null>((resolve, reject) => {
    const project = ALL_PROJECTS.find((x) => x.id === id) ?? null;
    setTimeout(() => {
      resolve(project);
    }, 300);
  });
}
