import "react-phone-number-input/style.css";
import PhoneInput, { Value } from "react-phone-number-input";
import { TextField } from "@mui/material";
import {
  ChangeEvent,
  ComponentProps,
  FocusEvent,
  forwardRef,
  ReactNode,
  useState,
} from "react";
import styled from "styled-components";
import useGeoLocation from "react-ipgeolocation";

export type PhoneNumberInputProps = {
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
  helperText?: ReactNode;
  disabled?: boolean;
};

export type CustomInputProps = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: boolean;
  helperText?: ReactNode;
  disabled?: boolean;
};

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ value, onChange, onFocus, onBlur, error, disabled }, ref) => {
    return (
      <TextField
        type="tel"
        hiddenLabel
        variant="filled"
        size="small"
        placeholder="Enter your phone number"
        autoComplete="none"
        fullWidth
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        inputRef={ref}
        inputProps={{ autoComplete: "none" }}
        error={error}
        disabled={disabled}
      />
    );
  }
);

type PhoneInputRootProps = ComponentProps<typeof PhoneInput> & {
  isFocused: boolean;
  error?: boolean;
};

const PhoneInputRoot = styled(PhoneInput)<PhoneInputRootProps>`
  .PhoneInputCountry {
    background-color: #202020;
    margin-right: 0;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    border-top-left-radius: 4px;
    max-height: 40px;

    border-bottom: ${(props) => (props.isFocused ? "2px" : "1px")} solid
      ${(props) =>
        props.isFocused || props.error
          ? "#fd0018"
          : "rgba(255, 255, 255, 0.7)"};
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    --PhoneInputCountryFlag-borderColor--focus: #fff;
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    --PhoneInputCountrySelectArrow-color--focus: #fff;
  }

  .PhoneInputCountryIcon {
    color: #fff;
  }

  .PhoneInputCountrySelectArrow {
    color: #fff;
  }

  .MuiFilledInput-input {
    padding-left: 6px;
  }

  .MuiInputBase-root {
    border-top-left-radius: 0;
  }
`;

const HelperText = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #f44336;
`;

function PhoneNumberInput({
  value,
  onChange,
  error,
  helperText,
  disabled,
}: PhoneNumberInputProps) {
  const loc = useGeoLocation();
  const [focused, setFocused] = useState(false);

  const currentCountry = loc.country || "";

  return (
    <>
      <PhoneInputRoot
        defaultCountry={currentCountry}
        isFocused={focused}
        value={value}
        onChange={(val: Value) => {
          onChange(val || "");
        }}
        onFocus={(e: any) => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        inputComponent={CustomInput}
        numberInputProps={{ error, helperText, value, onChange, disabled }}
        error={error}
      />
      {!!error && <HelperText>{helperText}</HelperText>}
    </>
  );
}

export default PhoneNumberInput;
