import { useState } from "react";
import { Blurhash } from "react-blurhash";
import styled from "styled-components";

export type LazyImageProps = {
  src: string;
  srcSet?: string;
  blurhash: string;
  alt: string;
  className?: string;
  watermark?: string | null;
};

const LazyImageRoot = styled.div`
  position: relative;
  width: 100%;

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    aspect-ratio: 16 / 9;
  }

  .blurhash-container {
    width: 100%;
  }

  .watermark {
    position: absolute;
    left: 0%;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;

    & > span {
      font-size: 1rem;
      line-height: 1;
      background-color: #272727;
      padding: 8px 16px 8px 16px;
      border-radius: 500px;
    }
  }
`;

function LazyImage({
  src,
  srcSet,
  alt,
  blurhash,
  className,
  watermark,
}: LazyImageProps) {
  const [loading, setLoading] = useState(true);

  return (
    <LazyImageRoot>
      <div
        className="blurhash-container"
        style={{ display: loading ? "block" : "none" }}
      >
        <Blurhash
          hash={blurhash}
          width="100%"
          height="197"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
      <div
        className={className}
        style={{ display: loading ? "none" : "block" }}
      >
        {!srcSet ? (
          <img src={src} alt={alt} onLoad={() => setLoading(false)} />
        ) : (
          <img srcSet={src} alt={alt} onLoad={() => setLoading(false)} />
        )}
      </div>
      {watermark && (
        <div className="watermark">
          <span>{watermark}</span>
        </div>
      )}
    </LazyImageRoot>
  );
}

export default LazyImage;
