import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import Home from "./Home";
import WhoWeAre from "./WhoWeAre";
import WhatWeDo from "./WhatWeDo";
import Scroll from "react-scroll";

const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? "visible" : "hidden";
  }
};

function ScrollPages() {
  const loc = useLocation();

  const hash = loc.hash;

  useLayoutEffect(() => {
    toggleCaptchaBadge(false);
    return () => toggleCaptchaBadge(false);
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (hash.toLowerCase() === "#home") {
        const homePageSection = document.getElementById("home");
        if (homePageSection) {
          // homePageSection.scrollIntoView({ behavior: "smooth" });
          Scroll.scroller.scrollTo("home", { smooth: true });
        }
      } else if (hash.toLowerCase() === "#whoweare") {
        const whoWeArePageSection = document.getElementById("whoweare");
        if (whoWeArePageSection) {
          // whoWeArePageSection.scrollIntoView({ behavior: "smooth" });
          Scroll.scroller.scrollTo("whoweare", { smooth: true });
        }
      } else if (hash.toLowerCase() === "#whatwedo") {
        const whatWeDoPageSection = document.getElementById("whatwedo");
        if (whatWeDoPageSection) {
          // whatWeDoPageSection.scrollIntoView({ behavior: "smooth" });
          Scroll.scroller.scrollTo("whatwedo", { smooth: true });
        }
      }
    }, 200);
  }, [hash]);

  return (
    <>
      <Home />
      <WhoWeAre />
      <WhatWeDo />
    </>
  );
}

export default ScrollPages;
