import { useLayoutEffect } from "react";
import styled from "styled-components";
import Page from "../components/Page";
import TeamThumb from "../components/TeamThumb";
import { teamMembers } from "../config/teamStub";

const PeopleRoot = styled.div`
  width: 100%;
  background-color: #070707;
  min-height: 100vh;
  padding: 9rem 0.9375rem 0.9375rem 0.9375rem;

  .header {
    font-size: 2.5rem;
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #fd0018;
    text-underline-offset: 0.75rem;
    text-decoration-thickness: 0.3125rem;
    text-align: center;
  }

  .team-container {
    max-width: 1024px;
    padding: 80px 30px 30px 30px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
  }
`;

const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? "visible" : "hidden";
  }
};

function People() {
  useLayoutEffect(() => {
    toggleCaptchaBadge(false);
    return () => toggleCaptchaBadge(false);
  }, []);

  return (
    <Page id="people" $height="auto" $minHeight="100vh">
      <PeopleRoot>
        <h1 className="header mb-3">Meet Our People</h1>
        <div className="team-container">
          <TeamThumb personData={teamMembers[0]} />
          <TeamThumb personData={teamMembers[1]} />
          <TeamThumb personData={teamMembers[2]} />
        </div>
      </PeopleRoot>
    </Page>
  );
}

export default People;
