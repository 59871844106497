// import { useState } from "react";
import styled from "styled-components";
import SuccessCheckMark from "./SuccessCheckMark";
const ContactSuccessViewRoot = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .desc {
    text-align: center;

    h3 {
      color: #fff;
    }

    p {
      color: #c0c0c0;
    }
  }
`;

function ContactSuccessView() {
  // const [count, setCount] = useState(0);

  return (
    <ContactSuccessViewRoot>
      <div className="content">
        <SuccessCheckMark />
        <div className="desc">
          <h3>Thank you for contacting us.</h3>
          <p>We will get back to you shortly.</p>
        </div>
      </div>
    </ContactSuccessViewRoot>
  );
}

export default ContactSuccessView;
