export const SoftwareIconMap: any = {
  tdm: {
    name: "3Ds Max",
  },
  kj: {
    name: "Kujiale",
  },
  ps: {
    name: "Adobe Photoshop",
  },
  pr: {
    name: "Adobe Premiere Pro",
  },
};
