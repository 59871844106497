import YoutubePlayer from "react-player/youtube";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";

export type YoutubeMediaViewProps = {
  url: string;
};

const YoutubeMediaViewRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .youtube-player {
    position: absolute;
    width: 100%;
    height: 100%;

    & > div {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      & > iframe {
        position: absolute;
        top: 50%;
        height: auto;
        max-height: 100%;
        aspect-ratio: 16/ 9;
        transform: translateY(-50%);
      }
    }

    & > video {
      position: absolute;
      object-fit: fill;
    }
  }
`;

function YoutubeMediaView({ url }: YoutubeMediaViewProps) {
  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("User Swiped!", eventData),
  });

  return (
    <YoutubeMediaViewRoot {...handlers}>
      <YoutubePlayer
        className="youtube-player"
        width="100%"
        height="100%"
        url={url}
        config={{ playerVars: { modestbranding: 1, rel: 0, autoplay: 1 } }}
        controls
      />
    </YoutubeMediaViewRoot>
  );
}

export default YoutubeMediaView;
