import React, { ForwardedRef } from "react";
import { ReactNode } from "react";
import styled from "styled-components";

export type PageProps = {
  $position?: string;
  $height?: string;
  $minHeight?: string;
  $padding?: string;
  id: string;
  children: ReactNode;
};

const PageRoot = styled.main<Omit<PageProps, "children" | "id">>`
  position: ${(props) => props.$position};
  width: 100%;
  height: ${(props) => props.$height};
  min-height: ${(props) => props.$minHeight};
  padding: ${(props) => props.$padding};
  overflow-x: hidden;
  // min-height: -webkit-fill-available;
`;

const Page = React.forwardRef(
  (props: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { children, ...rest } = props;
    return (
      <PageRoot {...rest} ref={ref}>
        {children}
      </PageRoot>
    );
  }
);

Page.defaultProps = {
  $position: "static",
  $height: "100vh",
  $minHeight: "100vh",
  $padding: "unset",
};

export default Page;
