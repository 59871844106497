import Routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyles from "./globalstyles/globalstyles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#fd0018",
      },
      secondary: {
        main: "#202020",
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <GlobalStyles />
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
