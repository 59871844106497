import { CircularProgress } from "@mui/material";
import { ImgHTMLAttributes, useState } from "react";
import { BiErrorAlt } from "react-icons/bi";

export type ImageProps = ImgHTMLAttributes<any>;

const Image = (props: ImageProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { alt, ...rest } = props;

  const handleImageLoad = () => {
    setLoading(false);
    setError(false);
  };

  const handleImageLoadError = () => {
    setLoading(false);
    setError(true);
  };

  return (
    <>
      {loading && !error && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <CircularProgress
            style={{ display: loading && !error ? "block" : "none" }}
          />
        </div>
      )}
      {!loading && error && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <BiErrorAlt color="red" /> Error loading image
        </div>
      )}

      <img
        {...rest}
        alt={alt}
        onLoad={handleImageLoad}
        onError={handleImageLoadError}
        style={{
          opacity: loading ? 0 : !error ? 1 : 0,
          transition: "all 0.2s ease-in-out",
        }}
        loading="lazy"
      />
    </>
  );
};

export default Image;
