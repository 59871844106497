import { ComponentProps, useEffect, useLayoutEffect, useState } from "react";
import {
  Breadcrumbs,
  IconButton,
  Link,
  Paper,
  Skeleton,
} from "@mui/material";
import {
  Link as RouterLink,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import { breakPoints } from "../constants/breakpoints";
import { GrFormNext } from "react-icons/gr";
import { IoMdArrowRoundBack } from "react-icons/io";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ProjectCategory } from "../types/ProjectCategory";
import { GetCategoryByKey } from "../services/Projects";

const ProjectDetailsRoot = styled.main`
  position: relative;
  width: 100%;
  background-color: #070707;
  min-height: 100vh;
  padding: 7.5rem 0.5rem 0.5rem 0.5rem;

  @media ${breakPoints.lg} {
    padding: 8.75rem 0.9375rem 0.9375rem 0.9375rem;
  }

  .loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    padding: 0.9375rem;
  }

  .header {
    font-size: 2.5rem;
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #fd0018;
    text-underline-offset: 0.75rem;
    text-decoration-thickness: 0.3125rem;
    text-align: center;

    @media ${breakPoints.sm} {
      text-align: left;
      padding-left: 14px;
    }
  }

  .breadCrumbRoot {
    display: flex;
    align-items: center;
    padding: 8px 20px 8px 8px;
    background-color: #000;
    background-image: none;
  }

  .detailBreadCrumb {
    a {
      font-size: 0.875rem;
      @media ${breakPoints.lg} {
        font-size: 1.125rem;
      }
    }
    a:link,
    a:visited,
    a:active {
      color: #fff;
    }

    a:hover {
      color: #fff;
    }

    .MuiBreadcrumbs-separator {
      polyline {
        stroke: #fff;
      }
    }
  }
`;

function LinkRouter(
  props: ComponentProps<typeof Link> & { to: string; replace?: boolean }
) {
  return <Link {...props} component={RouterLink} />;
}

const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? "visible" : "hidden";
  }
};

function ProjectDetails() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [categoryInfo, setCategoryInfo] = useState<ProjectCategory | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  const { category = "" } = useParams();

  useLayoutEffect(() => {
    toggleCaptchaBadge(false);
    return () => toggleCaptchaBadge(false);
  }, []);

  useEffect(() => {
    async function VerifyProjectCategory() {
      try {
        setLoading(true);
        const response = await GetCategoryByKey(category);
        if (response) {
          setLoading(false);
          setCategoryInfo(response);
          setError(null);
        } else {
          navigate("/projects");
        }
      } catch {
        setLoading(false);
        setError("Something went wrong");
      }
    }

    VerifyProjectCategory();
  }, [category, navigate]);

  return (
    <ProjectDetailsRoot>
      {loading && (
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          variant="rounded"
          width="100%"
          height={44}
          animation="wave"
        />
      )}
      {!loading && error === null && categoryInfo !== null && (
        <>
          <Paper className="breadCrumbRoot">
            <IconButton
              color="default"
              aria-label="Go back to projects"
              size="small"
              sx={{ mr: 1 }}
              onClick={() => navigate("/projects")}
            >
              <IoMdArrowRoundBack />
            </IconButton>
            <div>
              <Breadcrumbs
                separator={<GrFormNext color="#fff" />}
                aria-label="breadcrumb"
                className="detailBreadCrumb"
              >
                <LinkRouter underline="hover" color="inherit" to="/projects">
                  Projects
                </LinkRouter>
                <LinkRouter
                  underline="hover"
                  color="inherit"
                  to={`/projects/${categoryInfo.key}`}
                >
                  {categoryInfo.name}
                </LinkRouter>
              </Breadcrumbs>
            </div>
          </Paper>
          <Outlet />
        </>
      )}
    </ProjectDetailsRoot>
  );
}

export default ProjectDetails;
