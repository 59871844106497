import { createGlobalStyle } from "styled-components";
import { breakPoints } from "../constants/breakpoints";
import softwareIcons from "../assets/software_icons.png";

const GlobalStyles = createGlobalStyle`

    html,body{
        scroll-behavior: smooth!important;
    }

    @media ${breakPoints.lg}{
        /* width */
        ::-webkit-scrollbar {
        width: 10px;
        }
    
        /* Track */
        ::-webkit-scrollbar-track {
        background: #f1f1f1;
        }
    
        /* Handle */
        ::-webkit-scrollbar-thumb {
        background: #888;
        }
    
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
        background: #fd0018;
        }
    }

    .offcanvas{
        --bs-offcanvas-width:100%;
        --bs-offcanvas-bg: rgba(5, 5, 5,0.8);
    }

    .offcanvas-end.show{
        .dropdown-menu-dark {
            --bs-dropdown-bg: transparent;
            --bs-dropdown-divider-bg: #666;
            .dropdown-item:focus,
            .dropdown-item:hover {
                --bs-dropdown-link-hover-color: #fd0018;
                --bs-dropdown-link-hover-bg: transparent;
            }
        }
    }

    .offcanvas-backdrop.show {
        opacity: .8;
    }

    .offcanvas-logo{
        width: 3rem;
        margin-right: .625rem;
    }

    .nav-link{
        --bs-nav-link-padding-y:1rem;
       
        &:not(:last-child){
            border-bottom: 1px solid #707070;
        }

        @media ${breakPoints.lg}{
            --bs-nav-link-padding-y:0.5rem;
            border-bottom: none!important;
        }
    }

   .fullscreen-backdrop{
    background-color: rgba(0,0,0,0.5);
   }

   .ico-tdm {
      width: 23px; height: 24px;
      background: url(${softwareIcons}) -48px -48px;
    }

    .ico-kj {
        width: 24px; height: 24px;
        background: url(${softwareIcons}) -8px -48px;
    }

    .ico-pr {
        width: 24px; height: 24px;
        background: url(${softwareIcons}) -8px -8px;
    }

    .ico-ps {
        width: 24px; height: 24px;
        background: url(${softwareIcons}) -48px -8px;
    }
`;

export default GlobalStyles;
