import { useRoutes } from "react-router-dom";
import Layout from "../components/Layout";
import ProjectDetails from "../pages/ProjectDetails";
import ProjectList from "../components/ProjectList";
import ComingSoon from "../pages/ComingSoon";
// import Persons from "../pages/Persons";
import Projects from "../pages/Projects";
import ScrollPages from "../pages/ScrollPages";
import ProjectDetailView from "../components/ProjectDetailView";
import People from "../pages/People";
import ContactUs from "../pages/ContactUs";

function Routes() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <ScrollPages /> },
        {
          path: "/projects",
          element: <Projects />,
          children: [
            { path: "", element: <ProjectList /> },
            { path: ":category", element: <ProjectList /> },
          ],
        },
        {
          path: "/projects/:category/:projectId",
          element: <ProjectDetails />,
          children: [{ path: "", element: <ProjectDetailView /> }],
        },
        {
          path: "/people",
          element: <People />,
        },
        {
          path: "/contact",
          element: <ContactUs />,
        },
        // {
        //   path: "/persons",
        //   element: <Persons />,
        // },
        { path: "/notfound", element: <ComingSoon id="other-page" /> },
        { path: "*", element: <ComingSoon id="other-page" /> },
      ],
    },
  ]);

  return routes;
}

export default Routes;
