import styled from "styled-components";
import { breakPoints } from "../../constants/breakpoints";
import { BsFileEarmarkPersonFill } from "react-icons/bs";
import { Skeleton } from "@mui/material";

const ProjectInfoSkeletonRoot = styled.div`
  width: 100%;
  padding-right: 0.625rem;

  @media ${breakPoints.lg} {
    width: 450px;
  }

  .title {
    font-size: 1.6875rem;
    color: #fff;
  }

  .desc {
    p {
      font-size: 1rem;
      font-weight: normal;
      color: #e1e1e1;
    }
  }

  .meta-info {
    margin-bottom: 0.75rem;

    span.name {
      display: flex;
      width: 100%;
      color: #e1e1e1;
      align-items: center;
      font-size: 0.9375rem;
      svg {
        margin-right: 0.5rem;
      }
    }
    .value {
      padding-left: 1.5rem;
      font-size: 1.2rem;
      color: #e1e1e1;
    }
  }
`;

function ProjectInfoSkeleton() {
  return (
    <ProjectInfoSkeletonRoot>
      <h3 className="title mb-3">
        <Skeleton sx={{ bgcolor: "grey.900" }} animation="wave" />
      </h3>
      <div className="desc">
        <Skeleton
          sx={{ bgcolor: "grey.900", fontSize: "1rem" }}
          animation="wave"
        />
        <Skeleton
          sx={{ bgcolor: "grey.900", fontSize: "1rem" }}
          animation="wave"
        />
        <Skeleton
          sx={{ bgcolor: "grey.900", fontSize: "1rem" }}
          animation="wave"
        />
        <Skeleton
          sx={{ bgcolor: "grey.900", fontSize: "1rem" }}
          animation="wave"
        />
      </div>
      <div className="meta">
        <div className="meta-info">
          <span className="name mb-1">
            <Skeleton
              sx={{ bgcolor: "grey.900", marginRight: "10px" }}
              animation="wave"
              variant="circular"
            >
              <BsFileEarmarkPersonFill />
            </Skeleton>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="rectangular"
              width={90}
              height={22}
            />
          </span>
          <div className="value">
            <Skeleton sx={{ bgcolor: "grey.900" }} animation="wave" />
          </div>
        </div>
        <div className="meta-info">
          <span className="name mb-1">
            <Skeleton
              sx={{ bgcolor: "grey.900", marginRight: "10px" }}
              animation="wave"
              variant="circular"
            >
              <BsFileEarmarkPersonFill />
            </Skeleton>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="rectangular"
              width={90}
              height={22}
            />
          </span>
          <div className="value">
            <Skeleton sx={{ bgcolor: "grey.900" }} animation="wave" />
          </div>
        </div>
        <div className="meta-info">
          <span className="name mb-1">
            <Skeleton
              sx={{ bgcolor: "grey.900", marginRight: "10px" }}
              animation="wave"
              variant="circular"
            >
              <BsFileEarmarkPersonFill />
            </Skeleton>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="rectangular"
              width={90}
              height={22}
            />
          </span>
          <div className="value">
            <Skeleton sx={{ bgcolor: "grey.900" }} animation="wave" />
          </div>
        </div>
      </div>
    </ProjectInfoSkeletonRoot>
  );
}

export default ProjectInfoSkeleton;
