import { motion, Variants } from "framer-motion";
import { SwipeEventData } from "react-swipeable";
import styled from "styled-components";
import Panzoom from "./PanZoom";

export type ImageMediaViewProps = {
  src: string;
  alt: string;
  onSwipe?: (event: SwipeEventData) => void;
  direction: string;
};

const ImageMediaViewRoot = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .panzoom {
    width: 100%;
  }
`;

const sliderVariant: Variants = {
  initial: (direction: string) => {
    return {
      x: direction === "forward" ? "100%" : "-100%",
      opacity: 0,
    };
  },
  visible: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: string) => {
    return {
      zIndex: 0,
      x: direction === "backward" ? "100%" : "-100%",
      opacity: 0.2,
    };
  },
};

function ImageMediaView({ src, alt, onSwipe, direction }: ImageMediaViewProps) {
  return (
    <ImageMediaViewRoot
      key={src}
      custom={direction}
      variants={sliderVariant}
      animate="visible"
      initial="initial"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
    >
      <Panzoom
        src={src}
        alt={alt}
        options={{
          doubleClick: "toggleZoom",
          zoomInCentered: true,
          panOnlyZoomed: true,
          maxScale: 3,
        }}
        onSwipe={onSwipe}
      />
    </ImageMediaViewRoot>
  );
}

export default ImageMediaView;
