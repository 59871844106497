import styled, { css } from "styled-components";
import { Project, ProjectMedia } from "../types/Projects";
import { BsYoutube } from "react-icons/bs";
import { TbView360 } from "react-icons/tb";
import Image from "./Image";

export type ProjectSwiperSlideProps = {
  project: Project;
  media: ProjectMedia;
  onSlideClick?: (mediaIndex: number) => void;
};

const ProjectSwiperSlideRoot = styled.div<{
  $mediaType: "Image" | "Youtube" | "360Deg";
}>`
  position: relative;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.$mediaType === "Image" &&
    css`
      cursor: zoom-in;
    `}

  ${(props) =>
    (props.$mediaType === "Youtube" || props.$mediaType === "360Deg") &&
    css`
      cursor: pointer;
    `}

    .action {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
    display: flex;
    align-items: flex-start;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 5rem;
    line-height: 1;
    padding: 0;
    transition: all 0.3s ease-in-out;
    transform-origin: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 0px 0px 5px #000;

    h5 {
      text-shadow: 0px 0px 5px #000;
    }

    &:hover {
      font-size: 5.5rem;
    }
  }
`;

function ProjectSwiperSlide({
  project,
  media,
  onSlideClick,
}: ProjectSwiperSlideProps) {
  const categoryKey = project.category.key;
  const directoryName = project.directoryName;

  const handleSlideClick = () => {
    const mediaIndex = project.mediaList.findIndex((x) => x.id === media.id);
    onSlideClick?.(mediaIndex);
  };

  if (media.type === "Image") {
    return (
      <ProjectSwiperSlideRoot
        className="media-image"
        $mediaType="Image"
        onClick={handleSlideClick}
      >
        <Image
          className="slide-image"
          src={`${process.env.PUBLIC_URL}/images/projects/${categoryKey}/${directoryName}/${media.content}`}
          alt={media.title}
        />
      </ProjectSwiperSlideRoot>
    );
  }

  if (media.type === "Youtube") {
    return (
      <ProjectSwiperSlideRoot
        className="media-youtube"
        $mediaType="Youtube"
        onClick={handleSlideClick}
      >
        <div className="action">
          <BsYoutube />
          <h5>Click to play video</h5>
        </div>
        <Image
          className="slide-image"
          src={`${process.env.PUBLIC_URL}/images/projects/${categoryKey}/${directoryName}/${media.thumb}`}
          alt={media.title}
        />
      </ProjectSwiperSlideRoot>
    );
  }

  if (media.type === "360Deg") {
    return (
      <ProjectSwiperSlideRoot
        className="media-360"
        $mediaType="Youtube"
        onClick={handleSlideClick}
      >
        <div className="action">
          <TbView360 />
          <h5>
            Click to play 360<sup>o</sup> video
          </h5>
        </div>
        <Image
          className="slide-image"
          src={`${process.env.PUBLIC_URL}/images/projects/${categoryKey}/${directoryName}/${media.thumb}`}
          alt={media.title}
        />
      </ProjectSwiperSlideRoot>
    );
  }

  return (
    <ProjectSwiperSlideRoot
      className="media-image"
      $mediaType="Image"
      onClick={handleSlideClick}
    >
      <Image
        className="slide-image"
        src={`${process.env.PUBLIC_URL}/images/projects/${categoryKey}/${directoryName}/${media.content}`}
        alt={media.title}
      />
    </ProjectSwiperSlideRoot>
  );
}

export default ProjectSwiperSlide;
