import { Skeleton } from "@mui/material";
import styled from "styled-components";

const ProjectMediaSwiperRoot = styled.div`
  width: 100%;
  height: calc(100vh - 230px);

  .swiper {
  }
`;

function ProjectSwiperSkeleton() {
  return (
    <ProjectMediaSwiperRoot>
      <Skeleton
        sx={{ bgcolor: "grey.900", width: "100%", height: "100%" }}
        variant="rectangular"
        animation="wave"
      />
    </ProjectMediaSwiperRoot>
  );
}

export default ProjectSwiperSkeleton;
