import { Alert, AlertTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { breakPoints } from "../constants/breakpoints";
import { GetProjectById } from "../services/Projects";
import { Project } from "../types/Projects";
import ExpandCollapseLine from "./ExpandCollapseLine";
import ProjectInfoSidebar from "./ProjectInfoSidebar";
import ProjectMediaSwiper from "./ProjectMediaSwiper";

const ProjectDetailViewRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  @media ${breakPoints.lg} {
    display: flex;
    flex-direction: row;
    padding: 0.9375rem;
  }

  .sidebar {
    display: flex;
    flex-direction: row;
    width: 100%;
    order: 2;
    @media ${breakPoints.lg} {
      width: 450px;
      min-height: calc(100vh - 230px);
      order: 1;
    }
  }

  .content {
    width: 100%;
    margin-bottom: 0.75rem;
    order: 1;

    @media ${breakPoints.lg} {
      width: calc(100% - 450px);
      padding-left: 20px;
      order: 2;
      margin-bottom: 0;
    }
  }
`;

function ProjectDetailView() {
  const { projectId = "0" } = useParams();

  const [project, setProject] = useState<Project | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function LoadProjectById() {
      try {
        if (/^[0-9]+$/.test(projectId)) {
          const projectObj = await GetProjectById(parseInt(projectId, 10));
          if (projectObj) {
            setProject(projectObj);
            setError(null);
          } else {
            setProject(null);
            setError("Invalid Project Id");
          }
        } else {
          setProject(null);
          setError("Invalid Project Id");
        }
      } catch {}
    }

    LoadProjectById();
  }, [projectId]);

  if (!!error) {
    return (
      <ProjectDetailViewRoot>
        <Alert severity="error" sx={{ width: "100%" }}>
          <AlertTitle>Error</AlertTitle>
          Oops! That is an invalid Project Id.
        </Alert>
      </ProjectDetailViewRoot>
    );
  }

  return (
    <ProjectDetailViewRoot>
      <div className="sidebar">
        <ProjectInfoSidebar project={project} />
        <ExpandCollapseLine />
      </div>
      <div className="content">
        <ProjectMediaSwiper project={project} />
      </div>
    </ProjectDetailViewRoot>
  );
}

export default ProjectDetailView;
