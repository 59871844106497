import { TeamMemberData } from "../types/Team";

export const teamMembers: TeamMemberData[] = [
  {
    id: 1,
    name: "Sunil",
    photoUrl: `${process.env.PUBLIC_URL}/images/team/Member1G.png`,
    designation: "Co-founder & CEO",
  },
  {
    id: 2,
    name: "Ajil",
    photoUrl: `${process.env.PUBLIC_URL}/images/team/Member2G.png`,
    designation: "Co-founder & CEO",
  },
  {
    id: 3,
    name: "Creative Designer",
    photoUrl: `${process.env.PUBLIC_URL}/images/team/Member3G.png`,
    designation: "Creative Designer",
  },
];
