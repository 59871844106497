import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import styled from "styled-components";
import { Grow, IconButton, Paper } from "@mui/material";
import { MdClose } from "react-icons/md";

export type FullScreenPopupProps = {
  show: boolean;
  onClose: (reason: "backdropClick" | "escapeKeyDown") => void;
  children: React.ReactNode;
};

const FullScreenPopupRoot = styled(Paper)`
  position: absolute;
  width: 98%;
  height: 98%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
  outline: none !important;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;

  .close-button {
    position: absolute;
    right: 42px;
    top: 42px;
    z-index: 99;
    background-color: #fd0018;
    border-radius: 50%;

    button {
      padding: 12px;
    }
  }

  .header {
    width: 100%;
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);

    .title {
      width: 100%;
    }
  }

  .body {
    width: 100%;
    height: 100%;
    background-color: rgba(24, 24, 24, 0.6);
  }
`;

function FullScreenPopup({ show, onClose, children }: FullScreenPopupProps) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={(_, reason) => onClose(reason)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        classes: {
          root: "fullscreen-backdrop",
        },
      }}
    >
      <Grow in={show} timeout={500}>
        <FullScreenPopupRoot elevation={24} square>
          <div className="close-button">
            <IconButton
              aria-label="delete"
              onClick={() => onClose("escapeKeyDown")}
              size="small"
            >
              <MdClose />
            </IconButton>
          </div>
          <div className="body">{children}</div>
        </FullScreenPopupRoot>
      </Grow>
    </Modal>
  );
}

export default FullScreenPopup;
