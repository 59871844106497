import { MdMail } from "react-icons/md";
import {
  AiFillLinkedin,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import styled, { css } from "styled-components";
import { TeamMemberData } from "../types/Team";

export type TeamMemberPhotoAlign = "left" | "center" | "right";

const TeamThumbRoot = styled.div<{
  $width: number;
  $height: number;
  $imageAlign: TeamMemberPhotoAlign;
  $bgColor: string;
}>`
  margin-bottom: 54px;
  .photo-container {
    position: relative;
    width: ${(props) => props.$width}px;
    height: ${(props) => props.$height}px;
    margin-bottom: 8px;

    .photo-bg {
      position: relative;
      width: ${(props) => props.$width}px;
      height: ${(props) => props.$height}px;
      background-color: ${(props) => props.$bgColor};
      .member-photo {
        position: absolute;
        bottom: 0;
        filter: grayscale(100%);
        transition: all 0.2s ease-in;

        &:hover {
          filter: none;
        }

        ${(props) =>
          props.$imageAlign === "left" &&
          css`
            left: 6px;
          `}

        ${(props) =>
          props.$imageAlign === "center" &&
          css`
            left: 50%;
            transform: translateX(-50%);
          `}

      ${(props) =>
          props.$imageAlign === "right" &&
          css`
            right: 6px;
          `}
      }
    }
  }

  .details {
    .name {
      margin: 0 0 3px 2px;
      color: #fff;
      font-weight: normal;
      font-size: 1.25rem;
    }
    .designation {
      margin: 0 0 3px 2px;
      color: #bbbbbb;
      font-weight: normal;
      font-size: 1rem;
    }

    ul.ext-links {
      list-style-type: none;
      margin: 0 0 0 2px;
      padding: 0;
      display: flex;
      gap: 10px;

      li {
        a {
          font-size: 1.5rem;
          color: #bbbbbb;
          &:link,
          &:visited,
          &:active {
            color: #bbbbbb;
          }
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
`;

export type TeamThumbProps = {
  width?: number;
  height?: number;
  imageAlign?: TeamMemberPhotoAlign;
  bgColor?: string;
  personData: TeamMemberData;
};

function TeamThumb({
  width = 300,
  height = 300,
  personData: { name, designation, photoUrl },
  imageAlign = "center",
  bgColor = "#ad2727",
}: TeamThumbProps) {
  return (
    <TeamThumbRoot
      $width={width}
      $height={height}
      $imageAlign={imageAlign}
      $bgColor={bgColor}
    >
      <div className="photo-container">
        <div className="photo-bg">
          <img src={photoUrl} alt={designation} className="member-photo" />
        </div>
      </div>
      <div className="details">
        <h4 className="name">{name}</h4>
        <span className="designation">{designation}</span>

        <ul className="ext-links">
          <li>
            <a href="/" rel="noopener noreferrer" target="_blank">
              <MdMail />
            </a>
          </li>

          <li>
            <a href="/" rel="noopener noreferrer" target="_blank">
              <AiFillLinkedin />
            </a>
          </li>
          <li>
            <a href="/" rel="noopener noreferrer" target="_blank">
              <AiFillInstagram />
            </a>
          </li>
          <li>
            <a href="/" rel="noopener noreferrer" target="_blank">
              <AiOutlineTwitter />
            </a>
          </li>
        </ul>
      </div>
    </TeamThumbRoot>
  );
}

export default TeamThumb;
