// import { useCallback, useRef } from "react";
// import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";
import styled from "styled-components";
// import { Engine } from "tsparticles-engine";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import Page from "../components/Page";
import { breakPoints } from "../constants/breakpoints";

const ComingSoonRoot = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(15 15 15);
  display: flex;
  flex-direction: column;
  align-items: center;

  .inside-container {
    position: absolute !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .red {
    color: #d90d1e;
    font-weight: 600;
  }

  .box {
    // background-color: rgba(4, 4, 4, 0.8);
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 150px);
    border-radius: 8px;
    padding: 15px;
    padding-top: 110px;
    z-index: 999;

    @media ${breakPoints.lg} {
      flex-direction: row;
      padding-top: 120px;
    }

    .content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media ${breakPoints.lg} {
        width: 60%;
      }
    }

    .logo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      text-align: center;

      @media ${breakPoints.lg} {
        width: 40%;
      }

      img {
        width: 50%;
        opacity: 0.7;

        @media ${breakPoints.lg} {
          width: 80%;
        }
      }
    }

    .header {
      font-family: "Open Sans", sans-serif;
      text-align: center;
      /* background: #8ebf40;
      background: linear-gradient(
        to right,
        #8ebf40 4%,
        #a5cc66 11%,
        #5e9bd4 23%,
        #3682c9 37%,
        #c33c9e 50%,
        #e11e64 78%,
        #ce4f31 91%,
        #ce3149 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
      color: #fff;
      margin: 0;
      font-size: 2rem;
      font-weight: 600;
      line-height: 1;

      @media (min-width: 768px) {
        font-size: 3rem;
      }
    }

    .details {
      padding-top: 10px;

      p.subhead {
        margin: 0;
        font-weight: 400;
        font-size: 1rem;
        color: #fff;
        text-align: center;
        line-height: 1.7;

        @media (min-width: 768px) {
          font-size: 1.5rem;
        }
      }

      address {
        padding-top: 20px;
        text-align: center;
        p {
          margin: 0;
          color: #fff;
          font-style: normal;
        }
      }

      .social-icons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 220px;
        margin: 0 auto;
        margin-top: 30px;

        a.social {
          text-decoration: none;
        }

        a.social:link,
        a.social:visited,
        a.social:active {
          color: #fff;
        }
      }

      .phone {
        margin-top: 15px;
      }
    }
  }
`;

function ComingSoon({ id }: { id: string }) {
  // const particlesInit = useCallback(async (engine: Engine) => {
  //   await loadFull(engine);
  // }, []);

  return (
    <Page id={id}>
      <ComingSoonRoot>
        {/* <Particles
          id="tsparticles"
          url={`${process.env.PUBLIC_URL}/particlesjs-config.json`}
          init={particlesInit}
          container={pageRef}
          options={{ fullScreen: false }}
          style={{ position: "absolute" }}
          canvasClassName="inside-container"
        /> */}
        <div className="box">
          <div className="logo">
            <img
              src={`${process.env.PUBLIC_URL}/images/UnderConstruction.svg`}
              alt="Web page is under development"
            />
          </div>
          <div className="content">
            <div className="content-wrapper">
              <h1 className="header">
                Work <span className="red">in progress...</span>
              </h1>
              <div className="details">
                <p className="subhead">We are still working on this page.</p>
                <p className="subhead">Visit this page later❤️</p>
                <address className="address">
                  <div>
                    <p>Arch Cab Studio</p>
                  </div>
                  <div>
                    <p>Shoranur P.O(679123)</p>
                  </div>
                  <div>
                    <p>Palakkad Dist.</p>
                  </div>
                  <div>
                    <p>Kerala</p>
                  </div>
                  <div className="phone">
                    <p>+91 82720 27202 (IN)</p>
                    <p>+971 5236 57674 (UAE)</p>
                  </div>
                </address>
                <div className="social-icons">
                  <a
                    href="https://www.instagram.com/archcabstudio"
                    target="_blank"
                    className="social"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram size="3rem" />
                  </a>
                  <a
                    href="https://www.facebook.com/archcabstudio/"
                    target="_blank"
                    className="social"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook size="3rem" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UClxGDOTJzm9nKi6LPcsuj7Q"
                    target="_blank"
                    className="social"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube size="3rem" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ComingSoonRoot>
    </Page>
  );
}

export default ComingSoon;
