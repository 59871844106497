import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { breakPoints } from "../constants/breakpoints";
import NavLink from "./NavLink";
import { Location } from "history";
import { useCallback } from "react";
import { Link } from "react-scroll";

type NavbarRootProps = { $bgColor: string };

const NavbarRoot = styled(Navbar)<NavbarRootProps>`
  transition: background-color 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
  background-color: ${(props) => props.$bgColor} !important ;
  padding-top: 1.25rem;

  --bs-navbar-nav-link-padding-x: 15px;
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0;

  --bs-navbar-toggler-border-color: ${(props) =>
    props.$bgColor === "transparent" ? "#9f9f9f" : "#939393"};

  --bs-navbar-toggler-icon-bg: ${(props) =>
    props.$bgColor === "transparent"
      ? "url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e')"
      : "url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e')"};

  ${(props) =>
    props.$bgColor === "transparent"
      ? css`
          background: rgb(33, 37, 41);
          background: linear-gradient(
            180deg,
            rgb(0 0 0) 0%,
            rgb(0 0 0 / 71%) 65%,
            rgba(250, 250, 250, 0) 100%
          );
        `
      : css`
          --bs-navbar-brand-color: #000;
          --bs-navbar-brand-hover-color: #000;
        `}
  @media ${breakPoints.md} {
    --bs-navbar-padding-x: 15px;
    --bs-navbar-padding-y: ${(props) =>
      props.$bgColor === "transparent" ? "30px" : "12px"};
  }

  @media ${breakPoints.xl} {
    --bs-navbar-padding-x: 30px;
    --bs-navbar-nav-link-padding-x: 25px;
  }

  .navbar-brand {
    --bs-navbar-brand-font-size: 22px;
    font-weight: 600;
    text-shadow: ${(props) =>
      props.$bgColor === "transparent" ? "0rem 0rem 14px black" : "none"};
    margin-right: 0;
    @media ${breakPoints.md} {
      --bs-navbar-brand-font-size: 28px;
      margin-right: 20px;
    }

    .logo {
      width: 3.5rem;
      height: auto;

      @media ${breakPoints.sm} {
        width: 4rem;
      }

      @media ${breakPoints.md} {
        width: 4.25rem;
      }
    }
  }
  .navbar-toggler {
    --bs-navbar-toggler-border-radius: 2px;
    border: none;
    color: ${(props) =>
      props.$bgColor === "transparent" ? "#9f9f9f" : "#939393"}!important;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    margin-bottom: 0.625rem;
    outline: none;
    &:focus {
      --bs-navbar-toggler-focus-width: 2px;
    }
  }

  .nav-link {
    display: inline-block;
    position: relative;
    --bs-nav-link-color: ${(props) =>
      props.$bgColor === "transparent" ? "#fff" : "#000"};
    --bs-nav-link-font-size: 0.875rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &.activeLink {
      color: #fd0018;
      font-weight: bold !important;
      transform: scale(1.05);
    }

    font-weight: ${(props) =>
      props.$bgColor === "transparent" ? "400" : "600"}!important;

    @media ${breakPoints.xl} {
      --bs-nav-link-font-size: 1rem;
    }

    text-shadow: ${(props) =>
      props.$bgColor === "transparent" ? "0px 0px 0.875rem black" : "none"};

    &:hover {
      color: #fd0018;
      transform: scale(1.05);
    }
  }

  .dropdown-menu-dark {
    --bs-dropdown-bg: rgba(0, 0, 0, 0.8);
    --bs-dropdown-divider-bg: #666;
    .dropdown-item:focus,
    .dropdown-item:hover {
      --bs-dropdown-link-hover-color: #fd0018;
      --bs-dropdown-link-hover-bg: transparent;
    }
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
  }

  .offcanvas-body {
    margin-bottom: 0.625rem;
  }
`;

function Topbar() {
  const offset = 150;

  const [expanded, setExpanded] = useState(false);

  const handleHamburgerClick = useCallback(() => {
    setExpanded((state) => !state);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const headerColorClass =
    window.scrollY > window.innerHeight - offset
      ? "rgba(255,255,255,0.9)"
      : "transparent";

  const [topBarBg] = useState(headerColorClass);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > window.innerHeight - offset) {
  //       if (!!topBarBg) {
  //         setTopBarBg("rgba(255,255,255,0.9)");
  //       }
  //     } else {
  //       if (topBarBg !== "transparent") {
  //         setTopBarBg("transparent");
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [topBarBg]);

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/");
  };

  const handleLinkClick = (e?: any) => {
    e?.preventDefault();
    setExpanded(false);
  };

  return (
    <NavbarRoot
      bg="dark"
      variant="dark"
      fixed="top"
      expand="lg"
      $bgColor="transparent"
      expanded={expanded}
      onToggle={(status: boolean) => {
        setExpanded(status);
      }}
      onSelect={() => {
        console.log("hererrr");
        setExpanded(false);
      }}
      collapseOnSelect
    >
      <Container fluid>
        <Navbar.Brand href="/" onClick={handleLogoClick}>
          <img
            src={`${process.env.PUBLIC_URL}/images/${
              topBarBg === "transparent"
                ? "logo-white-optimized.png"
                : "logo-black-optimized.png"
            }`}
            alt="Logo"
            className="d-inline-block align-text-middle me-2 logo"
          />
          ARCH CAB STUDIO
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-lg`}
          onClick={handleHamburgerClick}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
        >
          <Offcanvas.Header closeButton closeVariant="white">
            <div className="d-flex align-items-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/logo-white-optimized.png`}
                alt="Logo"
                className="offcanvas-logo"
              />
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                ARCH CAB STUDIO
              </Offcanvas.Title>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1">
              {location.pathname === "/" ? (
                <>
                  <Link
                    className="nav-link"
                    activeClass="activeLink"
                    to="home"
                    spy={true}
                    hashSpy
                    smooth={true}
                    duration={500}
                    delay={700}
                    onClick={handleLinkClick}
                  >
                    Home
                  </Link>
                  <Link
                    className="nav-link"
                    activeClass="activeLink"
                    to="whoweare"
                    spy={true}
                    hashSpy
                    smooth={true}
                    duration={500}
                    delay={700}
                    onClick={handleLinkClick}
                  >
                    Who we are
                  </Link>
                  <Link
                    className="nav-link"
                    activeClass="activeLink"
                    to="whatwedo"
                    spy={true}
                    hashSpy
                    smooth={true}
                    duration={500}
                    delay={700}
                    onClick={handleLinkClick}
                  >
                    What we do
                  </Link>
                </>
              ) : (
                <>
                  <NavLink
                    to="/#home"
                    isActive={(location: Location) =>
                      location.hash.toLowerCase() === "#home"
                    }
                    location={location}
                    navigate={navigate}
                    onClick={handleLinkClick}
                  >
                    Home
                  </NavLink>

                  <NavLink
                    to="/#whoweare"
                    isActive={(location: Location) =>
                      location.hash.toLowerCase() === "#whoweare"
                    }
                    location={location}
                    navigate={navigate}
                    onClick={handleLinkClick}
                  >
                    Who we are
                  </NavLink>
                  <NavLink
                    to="/#whatwedo"
                    isActive={(location: Location) =>
                      location.hash.toLowerCase() === "#whatwedo"
                    }
                    location={location}
                    navigate={navigate}
                    onClick={handleLinkClick}
                  >
                    What we do
                  </NavLink>
                </>
              )}

              <NavLink
                to="/projects"
                isActive={(loc) =>
                  !!loc.pathname &&
                  loc.pathname.toLowerCase().startsWith("/projects")
                }
                location={location}
                navigate={navigate}
                onClick={handleLinkClick}
              >
                Projects
              </NavLink>

              <NavLink
                to="/people"
                location={location}
                navigate={navigate}
                onClick={handleLinkClick}
              >
                People
              </NavLink>

              <NavLink
                to="/contact"
                location={location}
                navigate={navigate}
                onClick={handleLinkClick}
              >
                Contact us
              </NavLink>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </NavbarRoot>
  );
}

export default Topbar;
