import styled from "styled-components";
import { breakPoints } from "../constants/breakpoints";
import { BsFileEarmarkPersonFill } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { FaLaptopCode } from "react-icons/fa";
import { Project } from "../types/Projects";
import ProjectInfoSkeleton from "./Skeletons/ProjectInfoSkeleton";
import { Tooltip } from "@mui/material";
import { SoftwareIconMap } from "../config/softwareIconMap";

const ProjectInfoSidebarRoot = styled.div`
  width: 100%;
  padding-right: 0.625rem;

  @media ${breakPoints.lg} {
    width: 450px;
  }

  .title {
    font-size: 1.6875rem;
    color: #fff;
  }

  .desc {
    p {
      font-size: 1rem;
      font-weight: normal;
      color: #e1e1e1;
    }
  }

  .meta-info {
    margin-bottom: 0.75rem;

    span.name {
      display: flex;
      width: 100%;
      color: #e1e1e1;
      align-items: center;
      font-size: 0.9375rem;
      svg {
        margin-right: 0.5rem;
      }
    }
    .value {
      padding-left: 1.5rem;
      font-size: 1.2rem;
      color: #e1e1e1;
    }

    .softwareList {
      list-style-type: none;
      display: flex;
      margin: 0;
      padding: 0;
      gap: 10px;
      margin-top: 8px;

      .software {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export type ProjectInfoSidebarProps = {
  project: Project | null;
};

function ProjectInfoSidebar({ project }: ProjectInfoSidebarProps) {
  if (!project) {
    return <ProjectInfoSkeleton />;
  }

  return (
    <ProjectInfoSidebarRoot>
      <h3 className="title mb-3">{project.name}</h3>
      <div className="desc">
        <p dangerouslySetInnerHTML={{ __html: project.description }}></p>
      </div>
      <div className="meta">
        <div className="meta-info">
          <span className="name mb-1">
            <BsFileEarmarkPersonFill /> Client name
          </span>
          <div className="value">{project.clientName}</div>
        </div>
        <div className="meta-info">
          <span className="name mb-1">
            <HiLocationMarker /> Location
          </span>
          <div className="value">{project.location}</div>
        </div>
        <div className="meta-info">
          <span className="name mb-1">
            <FaLaptopCode /> Softwares used
          </span>
          <div className="value">
            <ul className="softwareList">
              {project.softwaresUsed?.map((sc) => (
                <li key={sc}>
                  <Tooltip title={SoftwareIconMap[sc].name} arrow>
                    <span className={`software ico-${sc}`} />
                  </Tooltip>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </ProjectInfoSidebarRoot>
  );
}

export default ProjectInfoSidebar;
