import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectCoverflow } from "swiper";
import styled from "styled-components";
import { Project } from "../types/Projects";
import ProjectSwiperSkeleton from "./Skeletons/ProjectSwiperSkeleton";
import { breakPoints } from "../constants/breakpoints";
import ProjectSwiperSlide from "./ProjectSwiperSlide";
import { useCallback, useRef, useState } from "react";
import FullScreenPopup from "./FullScreenPopup";
import FullscreenSlider from "./FullscreenSlider/FullscreenSlider";

const ProjectMediaSwiperRoot = styled.div`
  width: 100%;
  height: 320px;

  @media ${breakPoints.lg} {
    height: calc(100vh - 230px);
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #000000;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: zoom-in;
  }

  .swiper-slide img.slide-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .media-youtube {
    img.slide-image {
      opacity: 0.6 !important;
    }
  }

  .media-360 {
    img.slide-image {
      opacity: 0.6 !important;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    --swiper-navigation-size: 30px;
    @media ${breakPoints.lg} {
      --swiper-navigation-size: 40px;
    }
  }

  .swiper-pagination-bullet {
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-bullet-inactive-opacity: 0.6;
    border: 1px solid #000000;
  }

  .swiper-button-next,
  .swiper-button-prev,
  .swiper-pagination-bullet-active {
    --swiper-theme-color: #c90000;
  }
  .swiper-pagination-bullet {
    --swiper-pagination-bullet-size: 0.5rem;
    @media ${breakPoints.lg} {
      --swiper-pagination-bullet-size: 1rem;
    }
  }
`;

export type ProjectMediaSwiperProps = {
  project: Project | null;
};

function ProjectMediaSwiper({ project }: ProjectMediaSwiperProps) {
  // const autoPlaySettings: AutoplayOptions = {
  //   delay: 10000,
  //   disableOnInteraction: false,
  // };

  const swiperRef = useRef<SwiperRef | null>(null);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [showFullScreen, setShowFullScreen] = useState(false);

  const toggleAutoPlay = (status: boolean) => {
    if (!!swiperRef && !!swiperRef.current) {
      if (!status) {
        swiperRef.current.swiper.autoplay.stop();
      } else {
        swiperRef.current.swiper.autoplay.start();
      }
    }
  };

  const handleFullscreenPopupClose = useCallback(
    (reason: "backdropClick" | "escapeKeyDown") => {
      setShowFullScreen(false);
      toggleAutoPlay(true);
    },
    []
  );

  const handleSlideClick = useCallback((mediaIndex: number) => {
    setMediaIndex(mediaIndex);
    toggleAutoPlay(false);
    setShowFullScreen(true);
  }, []);

  if (!project) {
    return <ProjectSwiperSkeleton />;
  }

  return (
    <ProjectMediaSwiperRoot>
      <Swiper
        loop
        pagination={{
          clickable: true,
          type: "bullets",
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay, EffectCoverflow]}
        // autoplay={autoPlaySettings}
        autoplay={false}
        effect="slide"
        ref={swiperRef}
      >
        {project.mediaList &&
          project.mediaList.length > 0 &&
          project.mediaList.map((media) => (
            <SwiperSlide key={media.id}>
              <ProjectSwiperSlide
                project={project}
                media={media}
                onSlideClick={handleSlideClick}
              />
            </SwiperSlide>
          ))}
      </Swiper>

      <FullScreenPopup
        show={showFullScreen}
        onClose={handleFullscreenPopupClose}
      >
        <FullscreenSlider
          activeIndex={mediaIndex}
          totalCount={project.mediaList.length}
          project={project}
        />
      </FullScreenPopup>
    </ProjectMediaSwiperRoot>
  );
}

export default ProjectMediaSwiper;
