import { ProjectCategory } from "../types/ProjectCategory";
import { Project } from "../types/Projects";

export const ALL_CATEGORIES: ProjectCategory[] = [
  { key: "interior", name: "Interior Designing" },
  { key: "archdesign", name: "Architecture Design" },
  { key: "productrender", name: "Product Render" },
  { key: "vr", name: "Virtual Reality" },
];

export const ALL_PROJECTS: Project[] = [
  {
    id: 100,
    category: {
      key: "interior",
      name: "Interior Designing",
    },
    name: "Abhys",
    directoryName: "Abhys",
    description:
      "Bright interior design ideas are one of modern trends in decorating. Bright room colors are excellent for all who enjoy dynamic and bold interior design. Colors are very personal interior design elements, and inspirations for colorful home interiors can be found anywhere you go.",
    clientName: "Iconic Design",
    location: "Maharashtra",
    blurhash:
      "|OIhjURO~qt7RiRjoLRj%2%2M{%NWBayofWBofbb9EogoIWBxujFRjf5RjxvxuR.WWxukCf5WBj?WBt7WEt7WAbHofkCoLRjRjoeWBkCaeogt7bHV@oet7ofazogj@ayayjtj[ofayWAfQkCWCozkCofRjj@ofj[aykCae",
    mediaList: [
      {
        id: 137,
        type: "Image",
        title: "Abhys",
        content: "Abhys1.jpg",
      },
      {
        id: 138,
        type: "Image",
        title: "Abhys",
        content: "Abhys2.jpg",
      },
      {
        id: 139,
        type: "Image",
        title: "Abhys",
        content: "Abhys3.jpg",
      },
      {
        id: 140,
        type: "Image",
        title: "Abhys",
        content: "Abhys4.jpg",
      },
      {
        id: 141,
        type: "Image",
        title: "Abhys",
        content: "Abhys5.jpg",
      },
      {
        id: 142,
        type: "Youtube",
        title: "Abhys",
        content: "https://www.youtube.com/watch?v=esits_iwWYg",
        thumb: "Abhys4.jpg",
      },
      {
        id: 143,
        type: "360Deg",
        title: "Abhys",
        content: "https://yun.kujiale.com/design/3FO412IYF7BI/show",
        thumb: "Abhys2.jpg",
      },
    ],
    softwaresUsed: ["tdm", "kj", "ps", "pr"],
  },
  {
    id: 101,
    category: {
      key: "interior",
      name: "Interior Designing",
    },
    name: "VS Studios",
    directoryName: "VSStudios",
    description: `Why use the Pastel color Palette for Interior Design? <br/> 
      When you think of a home, you want something peaceful and relaxing. A mix of colors that are soothing to the eyes is a welcome treat and an instant mood-lift. You can use a color palette generator to get the best combination of colors!`,
    clientName: "V-Studio",
    location: "Bangalore",
    blurhash:
      "|OIhjURO~qt7RiRjoLRj%2%2M{%NWBayofWBofbb9EogoIWBxujFRjf5RjxvxuR.WWxukCf5WBj?WBt7WEt7WAbHofkCoLRjRjoeWBkCaeogt7bHV@oet7ofazogj@ayayjtj[ofayWAfQkCWCozkCofRjj@ofj[aykCae",
    mediaList: [
      {
        id: 128,
        type: "Image",
        title: "VS Studios",
        content: "VSStudios3.jpg",
      },
      {
        id: 129,
        type: "Image",
        title: "VS Studios",
        content: "VSStudios2.jpg",
      },
      {
        id: 131,
        type: "Image",
        title: "VS Studios",
        content: "VSStudios4.jpg",
      },
      {
        id: 132,
        type: "Image",
        title: "VS Studios",
        content: "VSStudios5.jpg",
      },
      {
        id: 130,
        type: "Image",
        title: "VS Studios",
        content: "VSStudios1.jpg",
      },
      {
        id: 133,
        type: "360Deg",
        title: "VS Studios 360 degree Video",
        content: "https://yun.kujiale.com/design/3FO3WWERU5JO/show",
        thumb: "VSStudios6.jpg",
      },
    ],
    softwaresUsed: ["tdm", "kj", "ps", "pr"],
  },
  {
    id: 102,
    category: {
      key: "interior",
      name: "Interior Designing",
    },
    name: "Kulangara",
    directoryName: "Kulangara",
    description: `The Kerala-Themed Traditional Home With A Modern Touch...<br/>
      Our ideology was to keep balance between traditional and modern style.The aim was to keep the design aesthetic of the entire apartment neutral in a way that one space flows into another seamlessly. The interiors of each of the rooms are modern-contemporary and unique in their own way.`,
    clientName: "Favr",
    location: "Kerala",
    blurhash:
      "|OIhjURO~qt7RiRjoLRj%2%2M{%NWBayofWBofbb9EogoIWBxujFRjf5RjxvxuR.WWxukCf5WBj?WBt7WEt7WAbHofkCoLRjRjoeWBkCaeogt7bHV@oet7ofazogj@ayayjtj[ofayWAfQkCWCozkCofRjj@ofj[aykCae",
    mediaList: [
      {
        id: 107,
        type: "Image",
        title: "Kulangara",
        content: "Kulangara1.jpg",
      },
      { id: 108, type: "Image", title: "Kulangara", content: "Kulangara2.jpg" },
      { id: 109, type: "Image", title: "Kulangara", content: "Kulangara3.jpg" },
      { id: 110, type: "Image", title: "Kulangara", content: "Kulangara4.jpg" },
      { id: 111, type: "Image", title: "Kulangara", content: "Kulangara5.jpg" },
      {
        id: 112,
        type: "360Deg",
        title: "Kulangara 360 degree Video",
        content: "https://yun.kujiale.com/design/3FO44FINTA6X/show",
        thumb: "Kulangara6.jpg",
      },
    ],
    softwaresUsed: ["tdm", "kj", "ps", "pr"],
  },
  {
    id: 103,
    category: {
      key: "interior",
      name: "Interior Designing",
    },
    name: "Studio Redefine",
    directoryName: "StudioRedefine",
    description:
      "Modern interior design gives you full creative freedom while maintaining a clean and clutter-free environment. Now, get to decorating and create the modern interior design home of your dreams!",
    clientName: "Studio Redefine",
    location: "Mumbai",
    blurhash:
      "|OIhjURO~qt7RiRjoLRj%2%2M{%NWBayofWBofbb9EogoIWBxujFRjf5RjxvxuR.WWxukCf5WBj?WBt7WEt7WAbHofkCoLRjRjoeWBkCaeogt7bHV@oet7ofazogj@ayayjtj[ofayWAfQkCWCozkCofRjj@ofj[aykCae",
    mediaList: [
      {
        id: 121,
        type: "Image",
        title: "Studio Redefine",
        content: "StudioRedefine1.jpg",
      },
      {
        id: 122,
        type: "Image",
        title: "Studio Redefine",
        content: "StudioRedefine2.jpg",
      },
      {
        id: 123,
        type: "Image",
        title: "Studio Redefine",
        content: "StudioRedefine3.jpg",
      },
      {
        id: 124,
        type: "Image",
        title: "Studio Redefine",
        content: "StudioRedefine4.jpg",
      },
      {
        id: 125,
        type: "Image",
        title: "Studio Redefine",
        content: "StudioRedefine5.jpg",
      },
      {
        id: 126,
        type: "Image",
        title: "Studio Redefine",
        content: "StudioRedefine6.jpg",
      },
      {
        id: 127,
        type: "360Deg",
        title: "Studio Redefine 360 degree Video",
        content: "https://yun.kujiale.com/cloud/design/3FO462FHD93H/show",
        thumb: "StudioRedefine4.jpg",
      },
    ],
    softwaresUsed: ["tdm", "kj", "ps", "pr"],
  },
  {
    id: 104,
    category: {
      key: "interior",
      name: "Interior Designing",
    },
    name: "Modarch",
    directoryName: "Modarch",
    description:
      "Here modern traditional interior design meets elegant proportions to create a bespoke space. And the result is ideal for formal hosting. On the one hand, the coffered ceiling with wood inlays brings depth and visual intrigue. On the other hand, modern tufted sofas frame a beautiful period fireplace.",
    clientName: "Modarch",
    location: "Kerala",
    blurhash: "LWIrTy_4?v%M%Mt7s:IV%3M{ofs;",
    mediaList: [
      {
        id: 101,
        type: "Image",
        title: "Modarch",
        content: "Modarch1.jpg",
        thumb: "",
      },
      { id: 102, type: "Image", title: "Modarch", content: "Modarch2.jpg" },
      { id: 103, type: "Image", title: "Modarch", content: "Modarch3.jpg" },
      { id: 104, type: "Image", title: "Modarch", content: "Modarch4.jpg" },
      { id: 105, type: "Image", title: "Modarch", content: "Modarch5.jpg" },
      { id: 106, type: "Image", title: "Modarch", content: "Modarch6.jpg" },
      // {
      //   id: 107,
      //   type: "Youtube",
      //   title: "Modarch Video",
      //   content: "https://www.youtube.com/watch?v=F3VRCHigfzo",
      //   thumb: "pikrepo.jpg",
      // },
      // {
      //   id: 108,
      //   type: "360Deg",
      //   title: "Modarch Video",
      //   content: "https://preview.finest.studio/design/3FO3WWERU5JO/show",
      //   thumb: "pikrepo.jpg",
      // },
    ],
    softwaresUsed: ["tdm", "kj", "ps", "pr"],
  },
  {
    id: 105,
    category: {
      key: "interior",
      name: "Interior Designing",
    },
    name: "Jaleel",
    directoryName: "Jaleel",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    clientName: "Jaleel",
    location: "Kerala",
    blurhash: "QKK,?6N1H=IVITaJo#IUo#~pM|NFMyV?WBRQWBt7V?s:x]V?t7tRkCx[V[",
    mediaList: [
      {
        id: 144,
        type: "Image",
        title: "Jaleel",
        content: "Jaleel1.jpg",
      },
      {
        id: 145,
        type: "Image",
        title: "Jaleel",
        content: "Jaleel2.jpg",
      },
      {
        id: 146,
        type: "Image",
        title: "Jaleel",
        content: "Jaleel3.jpg",
      },
      {
        id: 147,
        type: "Image",
        title: "Jaleel",
        content: "Jaleel4.jpg",
      },
      {
        id: 148,
        type: "Image",
        title: "Jaleel",
        content: "Jaleel5.jpg",
      },
      {
        id: 149,
        type: "Image",
        title: "Jaleel",
        content: "Jaleel6.jpg",
      },
      {
        id: 150,
        type: "Image",
        title: "Jaleel",
        content: "Jaleel7.jpg",
      },
      {
        id: 151,
        type: "Image",
        title: "Jaleel",
        content: "Jaleel8.jpg",
      },
      {
        id: 152,
        type: "Image",
        title: "Jaleel",
        content: "Jaleel9.jpg",
      },
    ],
    softwaresUsed: ["tdm", "kj", "ps", "pr"],
  },
  {
    id: 106,
    category: {
      key: "interior",
      name: "Interior Designing",
    },
    name: "Ratnakala",
    directoryName: "Ratnakala",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    clientName: "Ratnakala",
    location: "Kerala",
    blurhash: "Q9H1;=otngIA.88_%3r;^+~p^%%LIUWWozxuoJ%LD%-:RO-:adt7t7kDad",
    mediaList: [
      {
        id: 153,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala1.jpg",
      },
      {
        id: 154,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala2.jpg",
      },
      {
        id: 155,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala3.jpg",
      },
      {
        id: 156,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala3.jpg",
      },
      {
        id: 157,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala4.jpg",
      },
      {
        id: 158,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala5.jpg",
      },
      {
        id: 159,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala6.jpg",
      },
      {
        id: 160,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala7.jpg",
      },
      {
        id: 161,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala8.jpg",
      },
      {
        id: 162,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala9.jpg",
      },
      {
        id: 163,
        type: "Image",
        title: "Ratnakala",
        content: "Ratnakala10.jpg",
      },
      {
        id: 164,
        type: "Youtube",
        title: "Ratnakala Interior",
        content:
          "https://www.youtube.com/watch?v=49IVaGPXFyQ&feature=youtu.be&vq=1080",
        thumb: "Ratnakala1.jpg",
      },
      {
        id: 165,
        type: "360Deg",
        title: "Ratnakala Interior 360",
        content: "https://www.kujiale.com/cloud/design/3FO3WNI6RC9Y/show",
        thumb: "Ratnakala2.jpg",
      },
    ],
    softwaresUsed: ["tdm", "kj", "ps", "pr"],
  },
];
