import React from "react";
import styled from "styled-components";
import whatsAppChatImg from "../assets/whatsappChatIcon.png";
import { breakPoints } from "../constants/breakpoints";

const ChatFABRoot = styled.a`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: fixed;
  width: 64px;
  height: 64px;
  z-index: 99;
  right: 17px;
  bottom: 43%;
  background-color: #25d366;
  color: #fff;
  background-image: url("${whatsAppChatImg}");
  background-repeat: no-repeat;
  background-position: left top;
  box-shadow: 2px 2px 3px #000;
  border-radius: 32px;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  overflow: hidden;
  outline: none;
  cursor: pointer;

  &:link,
  &:visited,
  &:active,
  &:checked {
    color: #fff;
    outline: none;
    text-decoration: none;
  }

  @media ${breakPoints.lg} {
    right: 30px;
    bottom: 54%;
  }

  .text {
    transition-delay: 0.5s;
    opacity: 0;
    width: 0;
    height: 0;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
  }

  @media ${breakPoints.lg} {
    &:hover {
      width: auto;
      padding-left: 66px;
      padding-right: 15px;
      .text {
        opacity: 1;
        width: auto;
        height: auto;
      }
    }
  }
`;

export type ChatFABProps = {
  phonenumber: string;
};

function ChatFAB({ phonenumber }: ChatFABProps) {
  return (
    <ChatFABRoot
      href={`https://wa.me/${phonenumber}`}
      target="_blank"
      rel="noreferrer"
    >
      <span className="text">Chat with us</span>
    </ChatFABRoot>
  );
}

export default ChatFAB;
