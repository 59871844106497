import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import Page from "../components/Page";
import customMarker from "../assets/customMarker.png";
import { MdPhone, MdOutlineMail } from "react-icons/md";
import { GrMap } from "react-icons/gr";
import ContactForm from "../components/ContactForm";
import { breakPoints } from "../constants/breakpoints";
import ChatFAB from "../components/ChatFAB";

const ContactUsRoot = styled.div`
  width: 100%;
  background-color: #070707;
  height: 100%;
  padding: 0;

  .section-title {
    font-size: 2rem;
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #fd0018;
    text-underline-offset: 0.75rem;
    text-decoration-thickness: 0.3125rem;
    text-align: center;
  }

  header {
    position: relative;
    width: 100%;
    height: 24%;
    background-color: #000;
    background-image: url("/images/contactus/header.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media ${breakPoints.md} {
      height: 35%;
    }

    h1 {
      z-index: 2;
      color: #bebebe;
      font-weight: lighter;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      bottom: 0;
      background-color: #000000b5;
      z-index: 1;
    }
  }

  .contact-content {
    width: 100%;
    min-height: 65%;
    background-color: #464646;
    display: block;

    @media ${breakPoints.lg} {
      display: flex;
      flex-direction: row;
    }

    .content-section {
      flex: 1;
      flex-shrink: 0;
      width: 100%;

      &.map {
        background-color: #0a0a0a;
        min-height: 200px;

        @media ${breakPoints.md} {
          min-height: 0;
        }
      }

      &.address {
        background-color: #000000;
        padding: 30px;
      }

      &.contactform {
        background-color: #0a0a0a;
        padding: 30px;
      }
    }
  }

  .info-list {
    .info-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.3rem;
      font-weight: normal;
      color: #fff;

      svg {
        color: #fb0024;
        margin-right: 16px;

        path {
          stroke: #fb0024 !important;
        }
      }

      a {
        text-decoration: none;
      }

      a:link,
      a:visited,
      a:active {
        color: #fff;
      }

      a:hover {
        color: #fff;
      }
    }
  }
`;

const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? "visible" : "hidden";
  }
};

function ContactUs() {
  const mapRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    toggleCaptchaBadge(true);
    return () => toggleCaptchaBadge(false);
  }, []);

  useLayoutEffect(() => {
    async function initMap(): Promise<void> {
      if (mapRef.current !== null) {
        let map: google.maps.Map;
        //@ts-ignore
        const { Map } = await google.maps.importLibrary("maps");

        const officeCoordinates = new google.maps.LatLng({
          lat: 10.762282868942103,
          lng: 76.27011507055249,
        });

        map = new Map(mapRef.current, {
          mapId: "4372dad3cdb92040",
          center: officeCoordinates.toJSON(),
          zoom: 18,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
        });

        new window.google.maps.Marker({
          position: officeCoordinates.toJSON(),
          map: map,
          animation: window.google.maps.Animation.DROP,

          label: {
            className: "map-custom-marker-label",
            fontWeight: "bold",
            text: "Arch Cab Studio",
          },
          icon: {
            labelOrigin: new google.maps.Point(11, 65),
            url: customMarker,
            size: new google.maps.Size(32, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(11, 40),
          },
        });
      }
    }

    initMap();
  }, []);

  return (
    <Page id="contactus" $height="100vh" $minHeight="100vh">
      <ContactUsRoot>
        <header>
          <h1 className="display-3 mb-4">LET'S CONNECT</h1>
        </header>
        <div className="contact-content">
          <section className="content-section map" ref={mapRef}></section>
          <section className="content-section address">
            <h3 className="section-title mb-5">Meet Us</h3>
            <div className="info-list mt-3">
              <div className="info-list-item mb-4">
                <MdPhone />
                <span className="text">
                  <a
                    href="tel:08272027202"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    82720 27202
                  </a>
                </span>
              </div>
              <div className="info-list-item mb-4">
                <MdOutlineMail />
                <span className="text">
                  <a
                    href="mailto:studio@archcabstudio.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    studio@archcabstudio.com
                  </a>
                </span>
              </div>
              <div className="info-list-item align-items-start mb-4">
                <GrMap />
                <span className="text">
                  RJK Building <br />
                  MG Road
                  <br />
                  Opposite DYSP Office
                  <br />
                  Shoranur, Kerala <br />
                  PIN - 679121 <br />
                </span>
              </div>
            </div>
          </section>
          <section className="content-section contactform">
            <ContactForm />
          </section>
        </div>
      </ContactUsRoot>
      <ChatFAB phonenumber="+918272027202" />
    </Page>
  );
}

export default ContactUs;
