import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Project } from "../../types/Projects";
import ImageMediaView from "./ImageMediaView";
// import { useSwipeable } from "react-swipeable";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Button } from "@mui/material";
import YoutubeMediaView from "./YoutubeMediaView";
import VRMediaView from "./VRMediaView";
import { SwipeEventData } from "react-swipeable";
import { AnimatePresence } from "framer-motion";

export type FullscreenSliderProps = {
  activeIndex: number;
  totalCount: number;
  project: Project;
};

const FullscreenSliderRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .rotate-phone-prompt {
    position: absolute;
    z-index: 999;
    pointer-events: none;
    user-select: none;
    left: 0.625rem;
    top: 2.625rem;
    font-size: 0.75rem;
    width: 65%;

    img {
      width: 3.125rem;
      height: auto;
      margin-right: 0.25rem;
    }
  }

  .square {
    border-radius: 0;
    padding: 0.25rem;
    min-width: auto;
  }

  button.prevButton {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 99;
    transform: translateY(-50%);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  button.nextButton {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 99;
    transform: translateY(-50%);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
`;

function FullscreenSlider({
  activeIndex,
  project,
  totalCount,
}: FullscreenSliderProps) {
  const isInPortraitMode = () =>
    window.screen.orientation.type === "portrait-primary" ||
    window.screen.orientation.type === "portrait-secondary";

  const initialPromptVisibility = isInPortraitMode();

  const [showRotationPrompt, setShowRotationPrompt] = useState(
    initialPromptVisibility
  );

  const directionRef = useRef<string>("forward");
  const [index, setIndex] = useState(activeIndex);

  useEffect(() => {
    function handleResize() {
      setShowRotationPrompt(isInPortraitMode());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const media = !!project.mediaList ? project.mediaList[index] : null;

  const handlePrevClick = useCallback(() => {
    directionRef.current = "backward";
    if (index === 0) {
      setIndex(totalCount - 1);
    } else {
      setIndex((state) => state - 1);
    }
  }, [index, totalCount]);

  const handleNextClick = useCallback(() => {
    directionRef.current = "forward";
    if (index === totalCount - 1) {
      setIndex(0);
    } else {
      setIndex((state) => state + 1);
    }
  }, [index, totalCount]);

  const handleSwipe = useCallback(
    (event: SwipeEventData) => {
      if (event && event.dir === "Left") {
        handleNextClick();
      }
      if (event && event.dir === "Right") {
        handlePrevClick();
      }
    },
    [handleNextClick, handlePrevClick]
  );

  return (
    <FullscreenSliderRoot>
      {showRotationPrompt && (
        <div className="rotate-phone-prompt">
          <img
            src={`${process.env.PUBLIC_URL}/images/rotate-phone.gif`}
            alt="Rotate your phone to landscape"
          />
          Please rotate your phone
        </div>
      )}
      <Button
        className="prevButton"
        variant="contained"
        classes={{ root: "square" }}
        onClick={handlePrevClick}
      >
        <FiChevronLeft size="2rem" />
      </Button>
      <Button
        className="nextButton"
        variant="contained"
        classes={{ root: "square" }}
        onClick={handleNextClick}
      >
        <FiChevronRight size="2rem" />
      </Button>
      <AnimatePresence initial={false} custom={directionRef.current}>
        {media && media.type === "Image" && (
          <ImageMediaView
            src={`${process.env.PUBLIC_URL}/images/projects/${project.category.key}/${project.directoryName}/${media.content}`}
            alt={media.title}
            onSwipe={handleSwipe}
            direction={directionRef.current}
          />
        )}
        {media && media.type === "Youtube" && (
          <YoutubeMediaView url={media.content} />
        )}
        {media && media.type === "360Deg" && (
          <VRMediaView url={media.content} />
        )}
      </AnimatePresence>
    </FullscreenSliderRoot>
  );
}

export default FullscreenSlider;
