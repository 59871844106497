import Page from "../components/Page";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { forwardRef, useEffect } from "react";
import { motion, useAnimation, Variants } from "framer-motion";
import { breakPoints } from "../constants/breakpoints";

const WhoWeAreRoot = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: #070707;

  .hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35vh;

    @media ${breakPoints.lg} {
      height: 50vh;
    }

    .image-container {
      flex: 1;
      height: 100%;
      overflow: hidden;

      &.others {
        display: none;

        @media ${breakPoints.lg} {
          display: block;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.8s ease-in-out;
        transform: scale(1.001);

        @media ${breakPoints.lg} {
          filter: grayscale(1); /* IE5+ */

          &:hover {
            filter: none;
            -webkit-filter: grayscale(0);
            transform: scale(1.1) !important;
          }
        }
      }
    }
  }

  h1.mainhead {
    font-size: 2rem;
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #fd0018;
    text-underline-offset: 0.75rem;
    text-decoration-thickness: 0.3125rem;
    text-align: center;
    line-height: 1.5;
  }

  .content {
    max-width: 60rem;
    padding: 1.875rem 0.9375rem 0.9375rem 0.9375rem;
    margin: 0 auto;

    .about-desc {
      font-size: 0.9rem;
      color: #cbcbcb;
      @media ${breakPoints.lg} {
        font-size: 1.25rem;
      }
    }
  }

  .verybottom {
    position: absolute;
    left: 0;
    bottom: 0.75rem;
    width: 100%;
  }
`;

const heroImageFirstVariant: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0, duration: 0.5, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    y: "-100%",
    transition: { delay: 0, duration: 0.5, ease: "easeInOut" },
  },
};

const heroImageSecondVariant: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.2, duration: 0.5, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    y: "-100%",
    transition: { delay: 0.2, duration: 0.5, ease: "easeInOut" },
  },
};

const heroImageThirdVariant: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.6, duration: 0.5, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    y: "-100%",
    transition: { delay: 0.6, duration: 0.5, ease: "easeInOut" },
  },
};

const heroImageFourthVariant: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.8, duration: 0.5, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    y: "-100%",
    transition: { delay: 0.8, duration: 0.5, ease: "easeInOut" },
  },
};

const titleVariant: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.8, duration: 0.5, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    y: "-500%",
    transition: { delay: 0.8, duration: 0.5, ease: "easeInOut" },
  },
};

const contentVariant: Variants = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: { delay: 0.9, duration: 0.7, ease: "easeInOut" },
  },
  hidden: {
    opacity: 0.2,
    scale: 0,
    transition: { delay: 0.9, duration: 0.7, ease: "easeInOut" },
  },
};

const WhoWeAre = forwardRef<HTMLElement>(function WhoWeAre(_, sectionRef) {
  const control = useAnimation();

  const { ref, inView } = useInView({
    threshold: 1,
    root: null,
    rootMargin: "500px",
  });

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const PUBLIC_URL = process.env.PUBLIC_URL;
  return (
    <Page ref={ref} id="whoweare">
      <WhoWeAreRoot ref={sectionRef as any}>
        <div className="hero">
          <div className="image-container">
            <motion.img
              src={`${PUBLIC_URL}/images/whoweare/hero-image1.jpg`}
              alt="Hero1"
              loading="lazy"
              variants={heroImageFirstVariant}
              initial="hidden"
              animate={control}
            />
          </div>
          <div className="image-container others">
            <motion.img
              src={`${PUBLIC_URL}/images/whoweare/hero-image2.jpg`}
              alt="Hero2"
              loading="lazy"
              variants={heroImageSecondVariant}
              initial="hidden"
              animate={control}
            />
          </div>
          <div className="image-container others">
            <motion.img
              src={`${PUBLIC_URL}/images/whoweare/hero-image3.jpg`}
              alt="Hero3"
              loading="lazy"
              variants={heroImageThirdVariant}
              initial="hidden"
              animate={control}
            />
          </div>
          <div className="image-container others">
            <motion.img
              src={`${PUBLIC_URL}/images/whoweare/hero-image4.jpg`}
              alt="Hero4"
              loading="lazy"
              variants={heroImageFourthVariant}
              initial="hidden"
              animate={control}
            />
          </div>
        </div>
        <div className="text-center pt-2 m-0">
          <motion.h1
            className="title m-0 text-white mainhead"
            variants={titleVariant}
            initial="hidden"
            animate={control}
          >
            WHO WE ARE ?
          </motion.h1>
          <div className="content mt-1">
            <motion.p
              className="about-desc mb-0"
              variants={contentVariant}
              initial="hidden"
              animate={control}
            >
              A team of like-minded, yet multifaceted thinkers came together
              with the idea of creating wonders in interior design and workspace
              experiences. We undertake projects to surpass expectations as we
              understand the magnitude of trust placed on us by our valuable
              clients. Here, you are offered creativity combined with the right
              execution pattern that ensures the timely completion of your dream
              spaces, we understand the impact that the right spaces have on us.
            </motion.p>
          </div>
        </div>
        {/* <div className="d-flex align-items-center justify-content-center pb-3 verybottom">
          <HiOutlineChevronDown size="3rem" color="#9a9a9a" />
        </div> */}
      </WhoWeAreRoot>
    </Page>
  );
});

export default WhoWeAre;
