import Tab from "@mui/material/Tab/Tab";
import Tabs from "@mui/material/Tabs/Tabs";
import { useLayoutEffect } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import { breakPoints } from "../constants/breakpoints";

const ProjectsRoot = styled.main`
  width: 100%;
  background-color: #070707;
  min-height: 100vh;
  padding: 8.75rem 0.9375rem 0.9375rem 0.9375rem;

  .header {
    font-size: 2rem;
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #fd0018;
    text-underline-offset: 0.75rem;
    text-decoration-thickness: 0.3125rem;
    text-align: center;

    @media ${breakPoints.sm} {
      text-align: left;
      padding-left: 14px;
    }
  }

  .tab-wrapper {
    @media ${breakPoints.sm} {
      padding-left: 14px;
    }

    .MuiTabs-root {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);

      .MuiTab-textColorPrimary {
        color: #878787;
        &.Mui-selected {
          color: #fff;
          font-weight: 600;
        }
      }
      .MuiTabs-indicator {
        background-color: #fff;
      }
    }
  }
`;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? "visible" : "hidden";
  }
};

function Projects() {
  const { category = "interior" } = useParams();

  useLayoutEffect(() => {
    toggleCaptchaBadge(false);
    return () => toggleCaptchaBadge(false);
  }, []);

  return (
    <ProjectsRoot>
      <h1 className="header mb-4">Projects</h1>
      <div className="tab-wrapper">
        <Tabs value={category} aria-label="Project Tabs" variant="scrollable">
          <Tab
            label="Interior"
            {...a11yProps(0)}
            component={Link}
            to="/projects/interior"
            value="interior"
          />
          <Tab
            label="Architecture Design"
            {...a11yProps(1)}
            component={Link}
            to="/projects/archdesign"
            value="archdesign"
          />
          <Tab
            label="Virtual Reality"
            {...a11yProps(2)}
            component={Link}
            to="/projects/vr"
            value="vr"
          />
          <Tab
            label="Product Render"
            {...a11yProps(2)}
            component={Link}
            to="/projects/productrender"
            value="productrender"
          />
        </Tabs>
      </div>
      <section>
        <Outlet />
      </section>
    </ProjectsRoot>
  );
}

export default Projects;
