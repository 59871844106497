import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ContactFormState } from "../types/ContactUs";
import PhoneNumberInput from "./PhoneNumberInput";
import Button from "@mui/material/Button";
import { MdSend } from "react-icons/md";
import emailjs from "@emailjs/browser";
import { VerifyCaptcha } from "../services/Captcha";
import { useEffect, useState } from "react";
import ContactSuccessView from "./ContactSuccessView";
import Alert from "@mui/material/Alert";
import classNames from "classnames";

const ContactFormRoot = styled.div`
  .contact-form-wrap {
    display: block;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;

    .form-row {
      position: relative;
      width: 100%;

      .req {
        position: absolute;
        left: -15px;
        top: 0;
        color: #6c6c6c;
      }
    }
  }
`;

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  phone: Yup.string().required("Please enter phone number"),
});

function ContactForm() {
  const [error, setError] = useState<string | null>(null);
  const [view, setView] = useState<"form" | "success">("form");

  const serviceId = process.env.REACT_APP_EMAILJS_SID!;
  const templateId = process.env.REACT_APP_EMAILJS_TID!;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY!;

  const initialFormState: ContactFormState = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };

  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY!;

  const {
    values: { name, phone, email, message },
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useFormik<ContactFormState>({
    initialValues: {
      ...initialFormState,
    },
    onSubmit: (values, formikHelpers) => {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(captchaSiteKey, {
            action: "submit",
          })
          .then(async function (token) {
            // Add your logic to submit to your backend server here.
            try {
              formikHelpers.setSubmitting(true);
              const captchaVerificationResult = await VerifyCaptcha({ token });
              if (captchaVerificationResult.success) {
                await emailjs.send(serviceId, templateId, values, publicKey);
                setView("success");
              } else {
                setError("Error validating captcha please try again..");
                formikHelpers.setSubmitting(false);
                resetForm();
              }
            } catch {
              setError("Unexpected error please try again..");
              formikHelpers.setSubmitting(false);
              resetForm();
            }
          });
      });
      console.log(values);
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    setError(null);
  }, [name, phone, email, message]);

  return (
    <ContactFormRoot className="contact-form-root">
      <h3 className="section-title">Contact Us</h3>
      {!!error && (
        <Alert
          severity="error"
          className={classNames(
            { "mt-5": error === null },
            { "mt-4": error !== null }
          )}
        >
          {error}
        </Alert>
      )}
      {view === "form" && (
        <form
          className={classNames(
            "contact-form-wrap",
            { "mt-5": error === null },
            { "mt-3": error !== null }
          )}
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="form-row mb-3">
            <span className="req">*</span>
            <TextField
              hiddenLabel
              variant="filled"
              size="small"
              placeholder="Enter your name"
              autoComplete="none"
              fullWidth
              name="name"
              onChange={handleChange}
              error={!!touched.name && !!errors.name}
              helperText={errors.name}
              disabled={isSubmitting}
            />
          </div>
          <div className="form-row mb-3">
            <span className="req">*</span>
            <PhoneNumberInput
              value={phone}
              onChange={(val) => {
                setFieldValue("phone", val);
              }}
              error={!!touched.phone && !!errors.phone}
              helperText={errors.phone}
              disabled={isSubmitting}
            />
          </div>
          <div className="form-row mb-3">
            <TextField
              hiddenLabel
              variant="filled"
              size="small"
              placeholder="Enter your email"
              autoComplete="none"
              fullWidth
              name="email"
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </div>
          <div className="form-row mb-3">
            <TextField
              multiline
              rows={2}
              hiddenLabel
              variant="filled"
              size="small"
              placeholder="Enter your message"
              autoComplete="none"
              fullWidth
              name="message"
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </div>
          <div className="form-row d-flex justify-content-end">
            <Button
              type="button"
              variant="contained"
              color="secondary"
              className="me-2"
              onClick={() => {
                resetForm();
              }}
              disabled={isSubmitting}
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<MdSend />}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Send"}
            </Button>
          </div>
        </form>
      )}
      {view === "success" && <ContactSuccessView />}
    </ContactFormRoot>
  );
}

export default ContactForm;
