import styled from "styled-components";

const VRMediaViewRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .vrframe {
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 100%;
    aspect-ratio: 16/ 9;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export type VRMediaViewProps = {
  url: string;
};

function VRMediaView({ url }: VRMediaViewProps) {
  return (
    <VRMediaViewRoot>
      <iframe className="vrframe" src={url} title="VR Frame" frameBorder={0} />
    </VRMediaViewRoot>
  );
}

export default VRMediaView;
